import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
    Islogin, Logout, inviteAction, getActiveJobMela, setRedirectionLink
    // get_User_Type, get_User_District, get_genders
} from '../../actions/accountAction';
import { UnsetNotification } from '../../actions/notificationAction';
import store from '../../store';
import LanguageSelector from './LanguageSelector';
import Loader from 'react-loader-spinner';
import { translate } from 'react-multi-lang'
import 'react-notifications/lib/notifications.css';
import FontSizeChanger from 'react-font-size-changer';
import { Link } from 'react-router';
import { chunk } from '../../helpers'
import ServerSetting from '../../config.json';


const INVITES_TO_SHOW = 5

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basePath: window.location.protocol + '//' + window.location.host + '/'
        }
        if (localStorage.getItem('Islogin') === 'true' && localStorage.getItem('Islogin')) {
            store.dispatch(Islogin());
        }
        this.fraud = React.createRef()
        this.foreignLinks = React.createRef()
        store.dispatch(getActiveJobMela());



    }

    redirectToJobs = () => {
        document.getElementById('jobMelaModal').click()
        this.props.router.push('/job-placement-fair-employers')
    }

    redirectToJobsCloseRegisterModal = () => {
        document.getElementById('registerModal').click()
        this.props.router.push('/job-placement-fair-employers')
    }

    closeModal = () => {
        document.getElementById("registerModal").click()
    }



    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.notificationReducer && nextProps.notificationReducer.Notification.hasOwnProperty('type')) {
            switch (nextProps.notificationReducer.Notification.type) {
                case 'info':
                    NotificationManager.info('Info message');
                    break;
                case 'success':
                    NotificationManager.success(nextProps.notificationReducer.Notification.message, '', 9000);
                    break;
                case 'warning':
                    NotificationManager.warning(nextProps.notificationReducer.Notification.message, '', 9000);
                    break;
                case 'error':
                    NotificationManager.error(nextProps.notificationReducer.Notification.message, '', 9000);
                    break;
                default:
                    return;
            }
            store.dispatch(UnsetNotification());
        }
    }

    inviteAction = (val, job) => {
        store.dispatch(inviteAction({ status: val, request_from: job.user_id }))
    }

    checkUserType = () => {
        const userType = ["7", "6", "10", "12"]
        const filterindex = userType.findIndex((user) => user === this.props.actionReducer.Loginuser.user_type)
        return filterindex !== -1 ? true : false
    }

    logout() {
        store.dispatch(Logout());
    }

    clickedJobMela = () => {
        if (localStorage.getItem('Islogin') && localStorage.getItem('Islogin') === 'true') {
            this.props.router.push('jobfair')
        }
    }

    changeLanguage = (event) => {

        window.location.href = this.state.basePath + 'signup?type=' + event;
    }

    clickedJobMela = () => {
        // if (localStorage.getItem('Islogin') && localStorage.getItem('Islogin') === 'true') {
        this.props.router.push('jobfair')
        // }

    }

    getTooltip = () => {
        return 'To see the available vacancies, Click here'
        // const allMelas = this.props.actionReducer.allMelas
        // if (allMelas && allMelas.length > 0) {
        //     return `${allMelas[0].job_mela_description}`
        // }
    }

    closeForeignModal = () => {
        document.getElementById("foreignModal").click()
        store.dispatch(setRedirectionLink(`foreign-counselling-details`))
    }

    render() {
        let Mainprops = this.props;
        // console.log('Mainprops', Mainprops)
        let name = ''

        if (this.props.actionReducer.Loginuser && this.props.actionReducer.Loginuser.displayName) {
            name = this.props.actionReducer.Loginuser.displayName
        }

        let chatInvites = []
        if (Mainprops.actionReducer.chatInvites) {
            chatInvites = chunk(Mainprops.actionReducer.chatInvites, INVITES_TO_SHOW)
            chatInvites = chatInvites.length ? chatInvites[0] : []
        }

        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;

        return (
            <section className="top-header">
                {Mainprops.loaderReducer.loader &&
                    <div style={{ position: 'fixed', width: "100%", height: "100%", background: "#fdfdfd", opacity: 0.5 }}>
                    </div>
                }
                {Mainprops.loaderReducer.loader &&
                    <Loader style={{ position: 'fixed', top: '49%', left: '48%' }}
                        type="Rings"
                        color="#F1911C"
                        height={100}
                        width={100}
                    />
                }
                <div id="foreignModal" className="modal fade">
                    <div className="modal-dialog" style={{ 'maxWidth': '969px' }}>
                        <div className="modal-content d-block">
                            <div className="modal-body pt-2  pb-1 text-center">

                                <div className="d-flex align-items-center w-100 justify-content-center">
                                    <img alt="" src="/images/logo-new2.png" className="img-fluid pb-2 home-top-logo" />
                                </div>
                                
                                <h5 className="mx-auto  text-uppercase mb-3">{this.props.t('jobMela.pgrkam', { param: 'react' })}</h5>
                                <div className="row d-flex">
                                    <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex">
                                        <div className="first-click">
                                            <img alt="" src="/images/mod2.png" className="img-fluid pb-2 w-25" />
                                            <p> {this.props.t('homeContent.inst1', { param: 'react' })}</p>
                                            <Link to="/signin" className="btn btn-danger pt-1 pb-1" onClick={this.closeForeignModal} >{this.props.t('homeContent.clickToLogin', { param: 'react' })}</Link>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex">
                                        <div className="first-click">
                                            <img alt="" src="/images/mod1.png" className="img-fluid pb-2 w-25" />
                                            <p className={"pl-2 pr-2"}> {this.props.t('homeContent.inst2', { param: 'react' })}</p>
                                            <Link to="/signup?type=1&for_coun_register=1" className="btn btn-danger pt-1 pb-1" onClick={this.closeForeignModal}>{this.props.t('homeContent.clickToRegister', { param: 'react' })}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row m-0 M_alignCenter">
                        <div className="col-md-6 col-sm-6 col-lg-6 col-6 col-xl-6 top-icon joint">

                            {(localStorage.getItem('Islogin') && localStorage.getItem('Islogin') === 'true') &&
                                <ul className="d-flex mb-0">
                                    {Mainprops.route.pathname !== '/' && <li className="list-inline-item"> <Link to="/"> <i className="fa fa-home" aria-hidden="true" style={{ 'marginTop': '20%', 'padding': '5px', 'fontSize': '19px' }}></i></Link></li>}
                                    <li className="list-inline-item">
                                        <div className="dropdown drop-outer">
                                            {/* <Link className="drop-inner pull-left btn btn-secondary">{`${this.props.t('dashboard.welcome', { param: 'react' })} ${name},`}</Link> */}
                                            <Link className="drop-inner pull-left mr-1 btn btn-secondary " to="/myaccount">
                                            {`${this.props.t('dashboard.welcome', { param: 'react' })} ${name},`} 
                                            </Link>
                                            <i className="fa fa-chevron-down pull-left mt-3 dropdown-toggle Content-none" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                            <div className="dropdown-menu drop-menus" aria-labelledby="dropdownMenuLink" x-placement="bottom-start">
                                                <Link to="/change-password" className="dropdown-item">{this.props.t('changePassword.title', { param: 'react' })}</Link>
                                                <Link className="dropdown-item" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.logout()
                                                }} >{this.props.t('header.sign_out', { param: 'react' })}</Link>

                                            </div>
                                        </div></li>
                                    {/* <li className="list-inline-item" style={{ 'fontWeight': '700', 'textTransform': 'uppercase' }}><a
                                        href="/myaccount">{`${this.props.t('dashboard.welcome', { param: 'react' })}, ${name}`}</a></li> */}
                                    {/* <li className="list-inline-item"><a href="/#" onClick={(e) => {
                                        e.preventDefault();
                                        this.logout()
                                    }}>{this.props.t('header.sign_out', { param: 'react' })}</a></li> */}
                                    {/* {(localStorage.getItem('login_type') && localStorage.getItem('login_type') === 'Jobseeker') &&
                                        <li className="list-inline-item">
                                            <a href="/docs/Jobseeker_manual.pdf" target="_blank">{this.props.t('header.jobseekerManual', { param: 'react' })}</a>
                                        </li>
                                    }
                                    {(localStorage.getItem('login_type') && localStorage.getItem('login_type') === 'Indian Employer') &&
                                        <li className="list-inline-item">
                                            <a href="/docs/employer_manual.pdf" target="_blank">{this.props.t('header.employerManual', { param: 'react' })}</a>
                                        </li>
                                    } */}
                                
                                    {/* <li className="list-inline-item"><a href="https://twitter.com/ghargharrozgaar?lang=en" ><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li className="list-inline-item"><a href="https://www.instagram.com/pgrkam/" ><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                    <li className="list-inline-item"><a href="https://www.youtube.com/hashtag/pgrkam" ><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li> */}
                                    <li className="list-inline-item">
                                        <Link to="/user-manual" >{this.props.t('homeContent.userManual', { param: 'react' })}</Link>
                                    </li>
                                    {/* <li className="list-inline-item">
                                        <Link to="/screen-reader" >Screen Reader</Link>
                                    </li> */}
                                    {this.checkUserType() ?
                                        <li className="list-inline-item">
                                            {Mainprops.actionReducer.chatInvites && Mainprops.actionReducer.chatInvites.length ?
                                                <span className="notification-count">{Mainprops.actionReducer.chatInvites.length}</span> : null}
                                            <i className="fa fa-bell pull-left mt-3 dropdown-toggle Content-none" style={{ fontSize: "13px" }} id="dropdownMenuLink1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                            
                                            <div className={Mainprops.actionReducer.chatInvites && Mainprops.actionReducer.chatInvites.length ? "dropdown-menu drop-menus" : "drop-menus"} style={{ minWidth: "18rem" }} aria-labelledby="dropdownMenuLink1" x-placement="bottom-start">
                                                {chatInvites && chatInvites.length ? chatInvites.map((invite, key) => {
                                                    return (
                                                        
                                                        <div className="row pl-2" key={key}>
                                                            <div className='col-md-12' style={{marginLeft:"23%"}}><b>Request For Discussion</b></div>
                                                            <div className="col-md-3">
                                                                <img alt="asd" className="profile-img-small" src="/images/icon2.png" />
                                                            </div>
                                                            <div className="col-md-9"><span className="pl-1 d-block">{invite.name}</span>
                                                                <button className="btn btn-primary btn-primary2" onClick={() => this.inviteAction(1, invite)}>Accept</button><button onClick={() => this.inviteAction(0, invite)} className="btn btn-primary btn-primary2 ml-2">Decline</button>
                                                            </div>
                                                        </div>
                                                    )
                                                }) : null}
                                                {Mainprops.actionReducer.chatInvites && Mainprops.actionReducer.chatInvites.length > INVITES_TO_SHOW ? <div className="row p-2" >
                                                    <div className="col-md-12 text-center">
                                                        <Link className="date-clr" to="/invites">{this.props.t('homeContent.viewMore', { param: 'react' })}</Link>
                                                    </div>
                                                </div> : null}
                                            </div>
                                        </li> : null}
                                </ul>
                            }
                            {(!localStorage.getItem('Islogin') || localStorage.getItem('Islogin') === 'false') &&
                                <ul className="d-flex mb-0">
                                    {Mainprops.route.pathname !== '/' && <li className="list-inline-item"> <Link to="/"> <i className="fa fa-home" aria-hidden="true" style={{ 'marginTop': '20%', 'padding': '5px', 'fontSize': '19px' }}></i></Link></li>}

                                    {/* <li className="list-inline-item"><a
                                    href="/signin">{this.props.t('header.sign_in', {param: 'react'})}</a></li>
                                 */}
                                    {/* <li className="list-inline-item Sign up"> <a href="#"> Sign up </a></li> */}
                                    {/* {Mainprops.route.pathname !== '/signup' &&
                                <li className="list-inline-item Sign up">
                                    <select onChange={(e) => this.changeLanguage(e.target.value)}
                                            className="form-control select-login form-shadow" id="sel1" name="sellist1">
                                        <option value="">{this.props.t('banner.register_as', {param: 'react'})}</option>
                                        {Mainprops.actionReducer.UserSubType.length > 0 &&
                                        Mainprops.actionReducer.UserSubType.map(function (data, index) {
                                            return (
                                                <option key={index} value={data.id}>{data.name}</option>
                                            )
                                        })
                                        }
                                    </select>
                                </li>
                                } */}

                                    {/* <li className="list-inline-item">
                                        <a href="/docs/Jobseeker_manual.pdf" target="_blank">{this.props.t('header.jobseekerManual', { param: 'react' })}</a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="/docs/employer_manual.pdf" target="_blank">{this.props.t('header.employerManual', { param: 'react' })}</a>
                                    </li> */}
                                   
                                     {/* <LanguageSelector />
                                        <li className="list-inline-item">
                                            <FontSizeChanger
                                                targets={['#root #rootContent']}
                                                options={{
                                                    stepSize: 1,
                                                    range: 3
                                                }}
                                                customButtons={{
                                                    up: <span style={{ 'fontSize': '18px', 'marginRight': '10px' }}>A+</span>,
                                                    down: <span style={{ 'fontSize': '15px' }}>A-</span>,
                                                    style: {
                                                        color: '#000',
                                                        width: 'auto',
                                                        border: 'none'
                                                    },

                                                }}
                                            />
                                        </li>
                                        <li className="list-inline-item "> <Link to="/faq">{this.props.t('faq', { param: 'react' })}</Link></li> */}

                                        {/* <li className="list-inline-item"><a href="https://twitter.com/ghargharrozgaar?lang=en" ><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                        <li className="list-inline-item"><a href="https://www.instagram.com/pgrkam/" ><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                        <li className="list-inline-item"><a href="https://www.youtube.com/hashtag/pgrkam" ><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li> */}
                                        <li className="list-inline-item">
                                            <Link to="/user-manual" >{this.props.t('homeContent.userManual', { param: 'react' })}</Link>
                                        </li>
                                        <li className="list-inline-item">
                                        <Link to="/screen-reader" >Screen Reader</Link>
                                    </li>
                                       
                                    </ul>}
                        </div>
                        
                        <div className="col-md-6 col-sm-6 col-lg-6 col-6 col-xl-6 top-icon joint ">
                            <ul className="d-flex justify-content-end mb-0">
                                <LanguageSelector />

                                                                <li className="list-inline-item">
                                    <FontSizeChanger
                                        targets={['#root #rootContent']}
                                        options={{
                                            stepSize: 1,
                                            range: 3
                                        }}
                                        customButtons={{
                                            up: <span style={{ 'fontSize': '18px', 'marginRight': '10px' }}>A+</span>,
                                            down: <span style={{ 'fontSize': '15px' }}>A-</span>,
                                            style: {
                                                color: '#000',
                                                width: 'auto',
                                                border: 'none'
                                            },

                                        }}
                                    />
                                </li>
                                <li className="list-inline-item "> <Link to="/faq">{this.props.t('faq', { param: 'react' })}</Link></li>
                                {/* <li className="list-inline-item "> <span>Fields with <span style={{ fontWeight:"bold"}}>*</span> marked are mandatory</span></li> */}
                            </ul>
                        </div>
                        {/* <div className="blinkingtxtnew" style={{marginLeft:'42%', background:'#4caf50'}} data-toggle={!(localStorage.getItem('Islogin') && localStorage.getItem('Islogin') === 'true') ? "modal" : ""}
                            data-target={!(localStorage.getItem('Islogin') && localStorage.getItem('Islogin') === 'true') ? "#jobMelaModal" : ""} onClick={() => this.clickedJobMela()}>{this.props.actionReducer.activeJobMela && this.props.actionReducer.activeJobMela.jobMela ? this.props.actionReducer.activeJobMela.jobMela : ''}
                        </div>  */}
                    </div>
                </div>
                
			{/* <div className="modal fade" id="jobMelaModal" style={{ 'zIndex': '9999999999999' }}>
                    <div className="modal-dialog" style={{ 'maxWidth': '969px' }}>
                        <div className="modal-content d-block">
                            <div className="modal-body p-0 text-center" style={{ overflow: "hidden" }}>
                                <div className="position-relative">
                                    <img alt=""
                                        src={this.props.actionReducer.activeJobMela && this.props.actionReducer.activeJobMela.webImageLink ? `${SERVER_PATH}${this.props.actionReducer.activeJobMela.webImageLink}` : ''}
                                        // src='/images/Option-1.gif'
                                        className="img-fluid pb-0 jobmela_banner w-100" />
                                    <div className="jobmelotr">
                                         <div className="jobmela_inner">
                                            <Link className="blinkingtxt-btn lft_btn" data-dismiss="modal"
                                                data-target="#registerModal1" data-toggle="modal"><img src="/images/apply_online.gif" alt="Apply button" /></Link>


                                            <a className="blinkingtxt-btn lft_btn" href="/job-placement-fair-employers"><img src="/images/view_jobs.gif" alt="View jobs button" /></a>
                                        </div> 
                                        <ul className="text-left fair-date mb-0 wrapcls btm_mess">
                                            <li>
                                                <b style={{ fontWeight: '700' }}>{this.props.t('homeContent.jobMelaBannerText', { param: 'react' })}</b> <a href="/employment">{this.props.t('homeContent.clickHere', { param: 'react' })}</a>
                                            </li>
                                        </ul>
                                    </div></div>
                            </div>
                        </div>
                    </div>
                </div>  */}


                {/* <!-- navbar start --> */}
                <div id="testmodal" className="modal fade">
                    <div className="modal-dialog" style={{ 'maxWidth': '969px' }}>
                        <div className="modal-content d-block">
                            {/*  <!--   <button type="button" className="close " data-dismiss="modal">&times;</button> -->
									<!-- Modal body --> */}
                            <div className="modal-body pt-2  0 text-center">
                                {/*<img alt="" src="images/logo-new2.png"
                                    className="img-fluid pb-3 home-top-logo top_log_job_mela" />*/}
                                {/* <h3 className="mx-auto mb-3 placement">Punjab Job Fair (High End Job)</h3> */}
                                {/* <h4 className={"text-danger pt-3 pb-3 text-uppercase"}>Punjab placement fair has been postponed till further notification by govt.  </h4> */}
                                <div className="abt_mela">
                                    {/* <p className={"pl-3"}>A State level event ‘Punjab Placement Fair’
                                    has been planned to be organized in the month of March’2020.
                                    This Placement Fair will target employment facilitation for jobs
													having annual pay package of INR 3 Lacs or above.</p>

                                    <ul className={"text-left fair-date pl-3"}>

                                        <li>  Punjab Ghar Ghar Rojgar and Karobar Mission has Planned a High End job Fair during March 2020.</li>
                                        <li> High End Job Fair Shall be held at the places mentioned below:</li>


                                        <div className={"table-responsive"}>
                                            <img src={'./images/IMG_20200302_102538.jpg'} alt="Events" className="p-2 w-75 mx-auto d-block img-fluid" />*!/
														 <table className="table table-borderless mt-2 pb-1">
                                                <thead>
                                                    <tr>
                                                        <th>S. NO</th>
                                                        <th>Date of the Event</th>
                                                        <th>Name Of the District</th>
                                                        <th className="w-50">Venue</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>12-13 March, 2020</td>
                                                        <td>Amritsar</td>
                                                        <td>Amritsar Group of Colleges 12 km, Stone GR Road, NH3, Meharbanpur,Punjab</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>17-18 March, 2020</td>
                                                        <td>Kapurthala</td>
                                                        <td>Lovely Professional University, Jalandhar-Delhi G.T. Road, Phagwar, Punjab.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>19-20 March, 2020</td>
                                                        <td>Bathinda</td>
                                                        <td>MRS PTU-Bathinda, Dabwali Road, Lal Singh Nagar,Bathinda, Punjab.</td>

                                                    </tr>

                                                    <tr>
                                                        <td>4</td>
                                                        <td>24 March, 2020</td>
                                                        <td>Mohali</td>
                                                        <td>
                                                            <p>(a) Govt College Phase-6,(SAS Nagar Mohali) </p>
                                                            <p>(b) NIPER Sec-67, Sahibjada Ajit Singh Nagar, Punjab 160062 (only on 24-03-2020)</p>
                                                            <p>(c)ISB Knowledge City, Sec-81, Manauli PO, Sahibjada Ajit Singh Nagar, Punjab 140306</p>
                                                            <p>(d) Chandigarh Group of Landran SAS Nagar Mohali</p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>5</td>
                                                        <td>23-24 March, 2020</td>
                                                        <td>SBS Nagar</td>
                                                        <td>Rayat Group of Institutions Rupnagar, Chandigarh-Jalandhar NH, VPO Railmajra, District SBS Nagar-144533.</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> */}
                                    <h4 className="pb-2 slogan">PUNJAB GHAR GHAR ROZGAR AND KAROBAR MISSION</h4>
                                    <div className="abt_mela">
                                        <img alt="" src="images/logo-new2.png" className="img-fluid pb-3 home-top-logo top_log_job_mela" style={{ position: 'absolute', left: '40%' }} />
                                        <img alt="" src="/images/ghrghr.gif" className="img-fluid pb-2 jobmela_banner" />
                                        {/* <div className="outerbtns">
                                            <div className="flexbtns">
                                                <a href="/signin?action=jobfair">
                                                    <img alt="" src="/images/apply_online.gif" className="img-fluid pb-2 apply_job_mela" /></a>
                                                <a href="/job-placement-fair-employers">
                                                    <img alt="" src="/images/ViewAvailableJobs.gif" className="img-fluid pb-2 view_job_mela" /></a>
                                                <a href="/docs/Vacancy_SepRozgar.pdf" target="_blank">
                                                    <img alt="" src="/images/DownloadList-New--.gif" className="img-fluid pb-2 download_job_mela" /></a>
                                            </div>
                                        </div> */}
                                        
                                        <div className="outerbtns">
                                            <div className="flexbtns ml-4">
                                                <a className="mr-4" href="/signup">
                                                    <button
                                                        className={"btn btn-primart btn-primary2 print-btn mx-auto d-block mb-2 mt-2"}>
                                                        Register with portal
                                                                </button></a>
                                                <a className="ml-4" href="/signin">
                                                    <button
                                                        className={"btn btn-primart btn-primary2 print-btn mx-auto d-block mb-2 mt-2"}>
                                                        Login into portal
                                                                </button></a>

                                            </div>
                                        </div>
                                        <ul className={"text-left fair-date pl-3"}>
                                            <li><b style={{ fontWeight: '700' }}>For assistance in participation, please contact the District Bureau office of your district, details of which are available</b>
                                                <a href="http://www.pgrkam.com/employment">here</a></li>
                                            {/*<li>To download the list of all available 90,000 vacancies, <a href="/docs/Vacancy_SepRozgar.pdf" target="_blank">Click Here</a></li>
                                        <li>To participate in the September Rozgar Mela Week and to apply for the available private vacancies, you have to login to your registered account on pgrkam.com</li>*/}
                                        </ul>
                                    </div>
                                </div>

                                {/* <div className="row w-100 mx-auto d-flex">
                                    <div className="col-md-6 col-lg-6 col-sm-6 mb-2 d-flex ">
                                        <div className="first-click">
                                            <img alt="" src="images/mod2.png"
                                                className="img-fluid pb-1 w-25" />
                                            <p> If you are already registerd on pgrkam.com</p>
                                            <a type="button" href="signin" className="btn btn-danger ">Click here to Login</a>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-6 mb-2 d-flex ">
                                        <div className="first-click">
                                            <img alt="" src="images/mod1.png"
                                                className="img-fluid pb-1 w-25" />
                                            <p> If you want to register on pgrkam.com</p>
                                            <a type="button" href="signup?type=1"
                                                className="btn btn-danger ">Click here to Register</a>
                                        </div>
                                    </div>
                                </div>
                            */}

                            </div>
                        </div>
                    </div>
                </div>
                {/* <div id="displaymodal1" className="modal fade">
                    <div className="modal-dialog" style={{ 'maxWidth': '969px' }}>
                        <div className="modal-content d-block" style={{ 'background': '#f6dfbb' }}>
                            <div className="modal-body pt-2  0 text-center">
                                <div className="abt_mela">
                                    <h4 className="pb-2 slogan">PUNJAB GHAR GHAR ROZGAR AND KAROBAR MISSION</h4>
                                    <p><strong>Govt. of Punjab is organizing a High End Virtual Job Fair from 24th to 30th Sept. 2020.</strong></p>
                                    <p>The students with backgrounds in all branches and Batches of 2019, 2020 and 2021 of Engineering, Management, Pharmacy, Polytechnic, General Graduates like BCA, BBA, B.Com etc of all educational institutions located in Punjab may participate in the event. The following companies are participating in the High End Virtual Job Fair September 2020.</p>
                                    <table className="w-100 mb-3">
                                        <tbody>
                                            <tr>
                                                <th>
                                                    S.No.</th>
                                                <th>Name of company</th>
                                                <th>No. of Vacancies</th>
                                                <th>Package( Rs.  Lacs)p.a.</th>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td>BYJU’S</td>
                                                <td>400</td>
                                                <td>8 - 10 Lacs</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>HCL</td>
                                                <td>530</td>
                                                <td>3.50-4.70 Lacs</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>HDFC LIFE</td>
                                                <td>28</td>
                                                <td>3 Lacs and above</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Trident</td>
                                                <td>245</td>
                                                <td>3 Lacs and above</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p>Besides the above companies, many more companies have confirmed their participation with 1300 vacancies.</p> */}
                {/* <p> Top MNC Microsoft is participating in Mega Virtual Job Fair being organised by Govt of Punjab under Punjab Ghar Ghar Rozgar and Karobar Mission ( PGRKAM) from September 24 to September 30,2020 . The details are as follows:</p> 
                           <p><strong>Eligiblity :-</strong></p>
                           <ol className="ml-3">
                               <li style={{'textAlign':'justify'}}><p style={{'fontWeight':'600'}}>BTech ( CSE, IT, ECE)</p>
                               <span> - Batch passing out in 2021, 2022 and 2023 are eligible</span><br></br>
                               <span> - Job Role : Software Engineer, Support Engineer, Technical Consultant, Customer Engineer</span><br></br>
                               <span> - Job Location : Hyderabad / Bangalore / Noida</span><br></br>
                               <span> - Salary Package (Full-time roles): INR 12 Lakh to INR 43 Lakhs pa (depending on role)</span><br></br>
                               <span> - Stipend : Intern INR 25000 per month to INR 80000 per month (depending on role)</span><br></br>
                               </li>
                               <li style={{'textAlign':'justify'}}><p style={{'fontWeight':'600'}}> MBA </p>
                               <p>Specializations:  Marketing, General Management, Information Management</p>
                               <span> - Eligibility : Candidate must have 3-6 years pre-MBA experience</span> <br></br>
                               <span> - Batch passing out in 2021/2022</span> <br></br>
                               <span> - Job Role : Business Program Management, Sales</span> <br></br>
                               <span> - Job Location : Hyderabad / Bangalore / Noida / Mumbai / Gurgaon</span> <br></br>
                               <span> - Salary package (Full-time): Commensurate to role and experience</span>
                               <span> - Stipend : Intern : INR 1,25,000 per month</span>
                               </li>
                            </ol>  */}
                {/* <p className="m-0">Interested candidates are required to <a rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSdgCemFjc_RhoRDfiESz4a95N4JX5nSkzv1wPKS6_ddeZwdww/viewform" target="_blank">Click here</a> and fill the details to participate. Last date to apply is 20th Sept. 2020 upto 5 PM:</p> */}
                {/* <p style={{'fontWeight':'600'}}>Last date to apply – 15th Sep’20 (Tuesday) 5:00 PM. Microsoft will shortlist the applicants based on their academic background as well as achievements.</p>   */}
                {/* 
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="modal fade" id="microsoftModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Microsoft AI Classroom Series</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row pl-3 pr-3">
                                    <div className="col-md-12" style={{background:"#1B1C1F"}}>
                                        <div className="mb-2 wid-80"><img className="img-fluid" src='./images/microsoft-2.png' alt="microsoft-2" /></div>
                                    </div>
                                    <div className="contentp">
                                        <p>Microsoft has joined forces with NASSCOM FutureSkills® to deliver Microsoft's AI, machine learning and data science expertise to students through a 3-part simulive learning series including demos and live Q&A*</p>
                                        <p>Upon successful completion of the course, you can download your Certificate of Participation**.</p>
                                        <div>
                                            <p>Module 1 - 11 AM – 1:30 PM October 22, 2020  <a href="https://tinyurl.com/AIClassroom-1" target="_blank"
                                                rel="noopener noreferrer">Register Now</a></p>
                                            <p>Module 2 - 11 AM – 1:30 PM October 23, 2020  <a href="https://tinyurl.com/AIClassroom-2" target="_blank"
                                                rel="noopener noreferrer">Register Now</a></p>
                                            <p> Module 3 - 11 AM – 1:30 PM October 24, 2020  <a href="https://tinyurl.com/AIClassroom-3" target="_blank"
                                                rel="noopener noreferrer">Register Now</a></p>
                                        </div>
                                    </div>
                                    <div className="float-left"><img src='./images/microsoft-1.png' alt="microsoft-1" className="img-fluid" /></div>
                                    <div className="mt-2">
                                        <p className="p-0">*Q&A will be via live chat with the subject matter experts.**Conditions apply</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primart btn-primary2" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div> */}
                <nav className="navbar navbar-expand-md bg-dark2  mb-0 pb-22">
                    <div className="container container-big pl-2 d-flex pr-2 M_nowrap"style={{ "maxWidth": "1250px"}}>
                        <div className="row logo-wid m-0 nowrap">
                            <Link to="/" className="navbar-brand p-0 m-0 mx-auto d-flex">
                                <img alt=""
                                    src={"/images/logo-new2.png"}
                                    className="img-fluid mx-auto d-block w-50 mauto w-100" />
                            </Link>
                            <h2 className="nav-heading fontsmall" style={{ "fontSize": "18px", "textAlign":"left", "marginLeft":"6px" }}>{this.props.t('banner.ggr', { param: 'react' })} <br /> 
                            <span className="n1 extrasmall-subtitle" style={{ "fontWeight": "600", "lineHeight":"20px" }}>{this.props.t('banner.subtitle_1', { param: 'react' })}</span><br/>
                            <span className="n1 extrasmall-subtitle" style={{ "fontWeight": "600", "lineHeight":"20px" }}>{this.props.t('banner.subtitle_2', { param: 'react' })}</span><br/>
                            
                            {/* <span className="n1 extrasmall" style={{ "fontWeight": "300", "lineHeight":"25px" }}>
                                
                                <Link to="/private-jobs"
                                >{this.props.t('banner.jobPlacements', { param: 'react' })} </Link>|<a rel="noopener noreferrer" href="http://psdm.gov.in/" target="_blank"
                                > {this.props.t('banner.skillDevelopment', { param: 'react' })}</a> | <a href="/docs/ਘਰ-ਘਰ%20ਰੋਜਗਾਰ-ਸਵੈ%20ਰੋਜ਼ਗਾਰ%20ਸਬੰਧੀ%20ਕਿਤਾਬਚਾ.pdf"
                                    target="_blank">{this.props.t('homeContent.selfEmp', { param: 'react' })}</a> </span> */}
                                    </h2>
                        </div>
                        <div className="row  m-0 outr-notify" id={"job-mela-tooltip"}
                            data-placement="bottom">
                            {/* {(localStorage.getItem('Islogin') === 'false' || localStorage.getItem('login_type') === "Jobseeker/Foreign Study Seeker") && this.props.actionReducer.activeJobMela && this.props.actionReducer.activeJobMela.hasOwnProperty('expire') && !this.props.actionReducer.activeJobMela.expire ?
                                // <img
                                //     style={{ cursor: 'pointer' }}
                                //     onClick={() => this.clickedJobMela()}
                                //     data-toggle={!(localStorage.getItem('Islogin') && localStorage.getItem('Islogin') === 'true') ? "modal" : ""}
                                //     data-target={!(localStorage.getItem('Islogin') && localStorage.getItem('Islogin') === 'true') ? "#jobMelaModal" : ""}
                                //     alt="Job mela banner" src={this.props.t('menu.jobMelaImage', { param: 'react' })}
                                //     className="img-fluid w-75 mb-2 mx-auto d-flex align-items-center mob-banner" /> 
                                
                                : null */}
                            {/* } */}
                             {/* <div className="blinkingtxtnew"> 
                                  
                                  <Link className="blinkingtxtnew" to= {!(localStorage.getItem('Islogin')) || !(localStorage.getItem('Islogin') === 'true') ? '/job-placement-fair-employers': '/jobfair'}>Job Fair in Kapurthala on 30th Nov 21</Link></div>  */}
                        </div>
                        {/* <a href={'https://play.google.com/store/apps/details?id=com.pgrkam'} className="date-clr" ><img src="images/android-logo.png" style={{'width':'50%','marginLeft':'20%'}}/></a> */}
                         <div className="row m-0 outr-notify" id={"job-mela-tooltip"} data-placement="bottom">
                            {/* {(localStorage.getItem('Islogin') === 'false' || localStorage.getItem('login_type') === "Jobseeker") && this.props.actionReducer.activeJobMela && this.props.actionReducer.activeJobMela.hasOwnProperty('expire') && !this.props.actionReducer.activeJobMela.expire ? */}
                              
                               
                                {/* : null */}
                            {/* } */}
                        </div> 

                        <button className="navbar-toggler mx-auto  mb-2" type="button" data-toggle="collapse"
                            data-target="#collapsibleNavbar">
                            <span className="fa fa-bars"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="collapsibleNavbar">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <Link className="nav-link"
                                        to="/">{this.props.t('menu.home', { param: 'react' })}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link"
                                        to="/about">{this.props.t('menu.aboutUs', { param: 'react' })}</Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link className="nav-link"
                                        to="/services">{this.props.t('menu.services', { param: 'react' })}</Link>
                                </li> */}
                                <li className="nav-item">

                                    <Link className="nav-link"
                                        to="/contact-us">{this.props.t('menu.contactUs', { param: 'react' })}</Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link className="nav-link"
                                        to="/videos">{this.props.t('menu.videos', { param: 'react' })}</Link>
                                </li> */}
                            </ul>
                        </div>

                    </div>

                </nav>
                
                <div className="fraud-notify">
                    <marquee scrolldelay="10" behavior="scroll" direction="left" ref={this.fraud}>
                        <div onMouseLeave={() => this.fraud.current.start()} onMouseEnter={() => this.fraud.current.stop()}>
                            {/* <span className="mr-2"
                                style={{ textDecoration: 'none', color: "#fff", background: 'red', padding: '0 10px' }} >
                                <b style={{ fontSize: "15px" }}>{this.props.t('homeContent.jobMela', { param: 'react' })}</b> <a href="/docs/Job Mela April.pdf" target="_blank" rel="noopener noreferrer" style={{ color: "#000" }}><b>{this.props.t('homeContent.clickHere', { param: 'react' })}</b></a>
                            </span> | */}
                            {/* <span className="mr-2" data-toggle="modal" data-target="#jobMelaModal"
                                style={{ textDecoration: 'none', color: "#fff", background: 'red', padding: '0 10px', cursor: 'pointer' }} >
                                <b style={{ fontSize: "15px" }}>{this.props.t('homeContent.jobMelaNotice', { param: 'react' })}</b>
                            </span> | */}
                            {/* <Link className="ml-2"
                                style={{ textDecoration: 'none', color: "#000", background: 'yellow', padding: '0 10px' }} to="/foreign-study-campaign">
                                <b style={{ fontSize: "15px" }}>{this.props.t('homeContent.UK', { param: 'react' })}</b>
                            </Link> | */}
                             {/* <a className="ml-2"
                                style={{ textDecoration: 'none', color: "#fff", background: '#15bd15', padding: '0 10px' }} href="docs/Recruitment_ENGLISH.pdf" target="_blank">
                                <b style={{ fontSize: "15px" }}>Upcoming recruitments for 26,454 posts in various departments of Govt. Of Punjab</b>
                            </a> | */}
                             <a className="ml-2"
                                style={{ textDecoration: 'none', color: "#000", background: 'yellow', padding: '0 10px' }} href="docs/List-of-RAs-updated.pdf">
                                <b style={{ fontSize: "15px" }}>List of registered Recruiting Agents in Punjab-for sending abroad on work visa</b>
                            </a> |
                            {/* <a className="ml-2"
                                style={{ textDecoration: 'none', color: "#fff", background: '#15bd15', padding: '0 10px' }} href="https://www.eduzphere.com/freegovtexams">
                                <b style={{ fontSize: "15px" }}>{this.props.t('homeContent.govt_exam', { param: 'react' })}</b>
                            </a> | */}
                           
                            <Link className="ml-2"
                                style={{ textDecoration: 'none', color: "#fff", background: '#15bd15', padding: '0 10px' }} to="/fraudulent-cases">
                                <b style={{ fontSize: "15px" }}>{this.props.t('homeContent.fraud', { param: 'react' })}</b>
                            </Link> 
                            
                          {/*   | <Link
                                className="ml-2"
                                to={'/signup?type=1&for_coun_register=1'}
                                data-toggle="modal" data-target="#foreignModal"
                                
                                style={{ textDecoration: 'none', cursor: 'pointer' }}>
                                <b style={{ fontSize: "15px" }}>{this.props.t('header.foreignCounsellingRegistration', { param: 'react' })}</b>
                            </Link> */}
                            {/* | <Link
                                className="ml-2"
                                style={{ textDecoration: 'none' }}>
                                <b style={{ fontSize: "15px" }}>{this.props.t('header.foreignCounsellingRegistration', { param: 'react' })}</b>
                            </Link> <Link to="/foreign-module-info">{this.props.t('homeContent.clickHere', { param: 'react' })}</Link> */}
                            {/* | <Link
                                className="ml-2"
                                to={'/free-training'}
                                style={{ textDecoration: 'none' }}>
                                <b style={{ fontSize: "15px" }}>{this.props.t('header.freeTraining', { param: 'react' })}</b>
                            </Link> */}
                            {/* | <span
                                className="ml-2"
                                style={{ color: "#fff", background: 'red', padding: '0 10px' }}>
                                <b style={{ fontSize: "15px" }}>{this.props.t('homeContent.inst1Header', { param: 'react' })}</b>
                            </span> */}
                        </div>
                    </marquee>
                </div>
                {(!localStorage.getItem('Islogin') || localStorage.getItem('Islogin') === 'false') &&
                      <div className="icon-bar">
                      <a
                        href="https://www.facebook.com/DEGSDT"
                        className="facebook"
                        target="_blank"
                        aria-label="Visit our Facebook page"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a
                        href="https://twitter.com/DEGSDT"
                        className="twitter"
                        target="_blank"
                        aria-label="Visit our Twitter page"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/degsdt_punjab"
                        className="instagram"
                        target="_blank"
                        aria-label="Visit our Instagrame page"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/degsdt"
                        class="linkedin"
                        aria-label="Visit our LinkedIn page"
                      >
                        <i class="fa fa-linkedin"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/@DEGSDT"
                        className="youtube"
                        target="_blank"
                        aria-label="Visit our Youtube page"
                      >
                        <i className="fa fa-youtube"></i>
                      </a>
                    </div>
                }

                {/* <!-- navbar end --> */}
                <NotificationContainer />

            </section>);
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Header));
