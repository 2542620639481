import axios from 'axios';
import qs from 'qs';
import {
    SET_COUNSELLING_PROVIDER_PROFILE, SET_PROVIDER_COUNSELLING_TYPES,
    SET_ARCHIEVE_SESSION, SET_PROVIDER_COUNSELLING_MODES, SET_POST_COUNSELLING_STATUS,
    SET_SCHEDULED_SESSION, SET_UPCOMING_SESSION, SET_APPLIED_SESSION, SET_SESSION_APPLICANTS,
    SESSION_UPDATED, SET_APPLIED_SESSION_BY_SEEKER, SET_GENERATE_EXCEL, SET_SESSION_DATA,
    SET_RECOMMENDED_COUNSELLING, SET_FEEDBACK_LIST, SET_COUNSELOR_EDUCATION, SET_AREA_OF_INTEREST,INDIVIDUAL_REQUEST,INDIVIDUALS_REQUEST
} from './actionTypes';
import ServerSetting from '../config.json';
import { setNotification } from './notificationAction'
import { Logout, set_Loader, unset_Loader } from './accountAction'
import { getJobProviderAddress } from './employerAction'
import { getAppliedCounsellings } from './jobseekerAction'

let environment = (typeof process.env.NODE_ENV !== 'undefined' && process.env.NODE_ENV === 'production') ? 'production' : 'development';
let API_SERVER = ServerSetting[environment].API_SERVER;

export const getProfile = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'counselling-provider/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setProfile(resp.data.data[0]));
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};

export const removeProfileData = (field) => {
    const data = []
    data['auth_key'] = localStorage.getItem('token')
    data[field] = null
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'counselling-provider/delete', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getProfile())
                    dispatch(getJobProviderAddress())

                }
            })
            .catch(error => {
                // console.log(error);
            });
    };

}

export const getUserEducation = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'counsellor-education/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                dispatch(setUserEducation(resp.data))
            })
            .catch(error => {
                // console.log(error);
            });
    };
};

export const getIndividualRequest = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'searchcounselling/individual-request', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                dispatch(setIndividualRequest(resp.data))
            })
            .catch(error => {
                // console.log(error);
            });
    };
};

export const getIndividualsRequest = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'searchcounselling/individuals-request', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                dispatch(setIndividualsRequest(resp.data))
            })
            .catch(error => {
                // console.log(error);
            });
    };
};

export const deleteAccount = (data) => {
    console.log(data, "newdata")
    
        data['auth_key'] = localStorage.getItem('token')
        data['username'] = data?.mobile
    
        return dispatch => {
            dispatch(set_Loader(''))
            axios.post(API_SERVER + 'site/delete-account', qs.stringify(data))
                .then(resp => {
                    dispatch(unset_Loader())
                    if (resp.data.status === '401') {
                        dispatch(setNotification({ type: "error", message: resp.data.message }));
                    } else {
                        dispatch(setNotification({ type: "success", message: resp.data.message }));
                        dispatch(Logout());
                        // this.props.router.push('/')
                        // dispatch(getUserEducation())
                    }
                })
                .catch(error => {
                    // console.log(error);
                });
        };
    };
    


export const deleteEducation = (data) => {
    data['auth_key'] = localStorage.getItem('token')

    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'counsellor-education/delete', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getUserEducation())
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};


export const updateEducation = (data) => {
    data['auth_key'] = localStorage.getItem('token')

    let formData = new FormData();

    for (var key in data) {
        formData.append(key, !data[key] ? '' : data[key]);
    }


    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'counsellor-education/create', formData, { headers: { 'content-type': 'multipart/form-data' } })
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getUserEducation())
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};
export const updateProfile = (data) => {
    data['auth_key'] = localStorage.getItem('token')

    let formData = new FormData();

   
    if (data.profile_image && typeof data.profile_image === 'string') {
        delete data['profile_image']
    }
    if (data.education_certificate && typeof data.education_certificate === 'string') {
        delete data['education_certificate']
    }
    if (data.license && typeof data.license === 'string') {
        delete data['license']
    }
    if (data.license1 && typeof data.license1 === 'string') {
        delete data['license1']
    }
    if (data.license2 && typeof data.license2 === 'string') {
        delete data['license2']
    }
    

    for (var key in data) {
        formData.append(key, !data[key] ? '' : data[key]);
    }
    // formData.append('area_of_interest', data['area_of_interest'])

    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'counselling-provider/create', formData, { headers: { 'content-type': 'multipart/form-data' } })
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getProfile())
                    dispatch(getJobProviderAddress())

                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};



export const getFeedback = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'apply-counselling/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setFeedback([]));
                } else {
                    dispatch(setFeedback(resp.data.rows));
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};

export const postCounselling = (data) => {
    data['auth_key'] = localStorage.getItem('token')

    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'post-counselling-session/create', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(setPostCounsellingStatus(true))
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};



export const updateCounselling = (data) => {
    data['auth_key'] = localStorage.getItem('token')
    
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'counselling-provider/update', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(setPostCounsellingStatus(true))
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};


export const cancelSession = (id, reason) => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'post-counselling-session/delete', qs.stringify({ id, reason, auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getAllScheduledSession())

                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};
export const applySesssion = (data) => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'counselling-status/index', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getSessionApplicants(data.session_id))
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};


export const scheduleOnlineOrTelephonicSession = (data) => {
    data['auth_key'] = localStorage.getItem('token')
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'scheduled-counselling-session/telephonic', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                } else {
                    dispatch(getSessionApplicants(data.session_id, true))
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};
export const enableSession = (id) => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'post-counselling-session/update', qs.stringify({ id, auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getAppliedSession())

                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};



export const markAttendence = (data) => {
    return dispatch => {
        data['auth_key'] = localStorage.getItem('token')
        dispatch(set_Loader(''))

        axios.post(API_SERVER + 'apply-counselling/create', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getAppliedCounsellings())
                }

            })
            .catch(error => {
                // console.log(error);
            });
    };
};


export const getCounsellingType = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.get(API_SERVER + 'counsellingtype/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setCounsellingType(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};

export const getAreaOfInterest = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.get(API_SERVER + 'counsellor-interest/index')
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                dispatch(setAreaOfInterest(resp.data.rows));
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};


export const getRecommendedSession = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.get(API_SERVER + 'recommendedcounselling/index')
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    dispatch(setRecommendedSession(resp.data.data));
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};

export const getCounsellingById = (session_id) => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'post-counselling-session/index', qs.stringify({ session_id, auth_key: localStorage.getItem('token') }))
            .then(resp => {
                if (resp.data.status === '200') {
                    dispatch(setSessionData(resp.data.data))
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};

export const acceptOrReject = (mode, id, applied_user_id) => { //old api before changes
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'scheduled-counselling/view', qs.stringify({ mode, id, applied_user_id }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(sessionUpdated(true));
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getAppliedSession())

                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};

export const getCounsellingModes = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.get(API_SERVER + 'counselling-mode/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setCounsellingModes(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};

export const getScheduledSession = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'scheduled-counselling-session/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    dispatch(setScheduledSession(resp.data.data));
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};
export const getAllScheduledSession = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'scheduled-counselling/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    let data = []
                    resp.data.posted.forEach((r) => {
                        let shortlisted = resp.data.shortlist.filter((sel) => sel.id === r.id)
                        shortlisted = shortlisted ? shortlisted[0] : []
                        data.push({ ...r, ...shortlisted })
                    })
                    dispatch(setScheduledSession(data));
                }
            })
            .catch(error => {
                console.log(error);
                
            });
    };

};

export const getUpcomingSession = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'upcoming-session/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    dispatch(setUpcomingSession(resp.data.data));
                }
            })
            .catch(error => {
                // console.log(error);
                
            });
    };
};

export const getArchieveSession = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'counselling-archieve/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    dispatch(setArchieveSession(resp.data.data));
                }
            })
            .catch(error => {
                // console.log(error);
                
            });
    };
};

export const getCalendarSessions = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'counselling-archieve/canlender-data', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    dispatch(setArchieveSession(resp.data.data));
                }
            })
            .catch(error => {
                // console.log(error);
                
            });
    };
};


export const getSessionsAppliedBySeeker = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'session-applied/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    dispatch(setSessionsAppliedBySeeker(resp.data.data));
                } else {
                    dispatch(setSessionsAppliedBySeeker([]));
                }
            })
            .catch(error => {
                // console.log(error);
                
            });
    };
};

export const getAppliedSession = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'scheduled-counselling/create', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    let data = []
                    resp.data.posted.forEach((r) => {
                        let post = resp.data.selectedCount.filter((sel) => sel.session_id === r.id)
                        post = post ? post[0] : []
                        let shortlisted = resp.data.shortlist.filter((sel) => sel.id === r.id)
                        shortlisted = shortlisted ? shortlisted[0] : []

                        data.push({ ...r, ...post, ...shortlisted })
                    })
                    dispatch(setAppliedSession(data));
                }
            })
            .catch(error => {
                console.log(error);
                
            });
    };
};


export const setRecommendedSession = (data) => {
    return {
        type: SET_RECOMMENDED_COUNSELLING,
        data
    };
};
export const setSessionData = (data) => {
    return {
        type: SET_SESSION_DATA,
        data
    };
};
export const setAppliedSession = (data) => {
    return {
        type: SET_APPLIED_SESSION,
        data
    };
};
export const setSessionsAppliedBySeeker = (data) => {
    return {
        type: SET_APPLIED_SESSION_BY_SEEKER,
        data
    };
};

export const setGenerateExel = (status) => {
    return {
        type: SET_GENERATE_EXCEL,
        status
    };
};

export const getSessionApplicants = (session_id, action = false) => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'scheduled-counselling/index', qs.stringify({ auth_key: localStorage.getItem('token'), session_id }))
            .then(resp => {
               
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setSessionApplicants({ notFound: resp.data.message }))
                } else {
                  
                    dispatch(setSessionApplicants(resp.data));
                    if (resp.data && resp.data.rows && resp.data.rows.length) {
                        if (action) {
                            dispatch(setGenerateExel(true));
                        }
                    }
                }
            })
            .catch(error => {
                // console.log(error);
                
            });
    };
};

export const getShorlistedApplicants = (session_id) => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'scheduled-counselling-session/create', qs.stringify({ auth_key: localStorage.getItem('token'), session_id }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    dispatch(setSessionApplicants(resp.data.rows));
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};

export const getSessionCandidatesAttended = (session_id) => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'session-applied/view', qs.stringify({ auth_key: localStorage.getItem('token'), session_id }))
            .then(resp => {
                console.log('vhghgh', resp.data)
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    dispatch(setSessionApplicants(resp.data.data));
                }
            })
            .catch(error => {
                // console.log(error);
                
            });
    };
};


export const setSessionApplicants = (data) => {
    return {
        type: SET_SESSION_APPLICANTS,
        data
    };
};

export const sessionUpdated = (status) => {
    return {
        type: SESSION_UPDATED,
        status
    };
};



export const setUserEducation = (data) => {
    return {
        type: SET_COUNSELOR_EDUCATION,
        data
    };
};

export const setIndividualRequest = (data) => {
    return {
        type: INDIVIDUAL_REQUEST,
        data
    };
};

export const setIndividualsRequest = (data) => {
    return {
        type: INDIVIDUALS_REQUEST,
        data
    };
};


export const setAreaOfInterest = (data) => {
    return {
        type: SET_AREA_OF_INTEREST,
        data
    };
}

export const setProfile = (data) => {
    return {
        type: SET_COUNSELLING_PROVIDER_PROFILE,
        data
    };
};


export const setArchieveSession = (data) => {
    return {
        type: SET_ARCHIEVE_SESSION,
        data
    };
};


export const setScheduledSession = (data) => {
    return {
        type: SET_SCHEDULED_SESSION,
        data
    };
};
export const setUpcomingSession = (data) => {
    return {
        type: SET_UPCOMING_SESSION,
        data
    };
};

export const setCounsellingType = (data) => {
    return {
        type: SET_PROVIDER_COUNSELLING_TYPES,
        data
    };
};

export const setFeedback = (data) => {
    return {
        type: SET_FEEDBACK_LIST,
        data
    };
};


export const setCounsellingModes = (data) => {
    return {
        type: SET_PROVIDER_COUNSELLING_MODES,
        data
    };
};
export const setPostCounsellingStatus = (status) => {
    return {
        type: SET_POST_COUNSELLING_STATUS,
        status
    };
};