import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { postServices, servicePosted, getServiceCategory } from '../../../actions/lspAction';
import { getLspServices } from '../../../actions/accountAction';
import store from '../../../store';
import Sidebar from './Sidebar';
import "@kenshooui/react-multi-select/dist/style.css"
import Validation from "../../hoc/Validation";
import { Link } from 'react-router'

class PostService extends Component {

    constructor(props) {
        super(props);
        this.myRefDesc = React.createRef();
        this.myRefQual = React.createRef();
        this.state = {
            submitStatus: false,
            user_subtype: window.location.protocol + '//' + window.location.host + '/',
            Profile: [],
            error: "",
            servicesData: [],
            errorAge: '',
            serviceList: []
        }
        this.docFormats = [
            'image/png',
            'image/jpg',
            'image/jpeg'
            // 'application/pdf'
        ]

        store.dispatch(getLspServices());
        store.dispatch(getServiceCategory())
    }

    componentDidMount() {
        document.title = this.props.t('lsp_user.post_services_title', { param: 'react' })
    }

    handleProfileImage(e, key) {
        e.persist();
        const files = e.target.files
        const formatIndex = this.docFormats.findIndex(format => format === files[0].type)
        if (formatIndex !== -1) {
            const fileSize = Math.round((files[0].size / 2048));
            const Profile = this.state.Profile
            if (fileSize > 1024) {
                this.setState({ [key]: 'File size is too large' })
                setTimeout(() => this.setState({ [key]: '' }), 3000)
                e.target.value = null;
            } else {
                Profile['img'] = files[0]
                this.setState({ Profile })
            }
        } else {
            this.setState({ [key]: 'Only .jpg, .jpeg .png, and pdf formats are allowed' })
            setTimeout(() => this.setState({ [key]: '' }), 3000)
            e.target.value = null;
        }
    }

    componentWillUnmount() {
        store.dispatch(servicePosted(false))
    }


    handleProfileInput = async (e) => {
        const Profile = this.state.Profile
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'category_id') {
            let serviceList = []
            const filterCat = this.props.LspReducer.serviceCategory.filter((ser) => ser.category_id === value)
            if (filterCat && filterCat.length) {
                serviceList = filterCat[0].service
            }
            this.setState({ serviceList })
        }

        Profile[name] = value
        this.setState({ Profile })
    }

    handleJobInput = async (e) => {
        e.persist();
        const name = e.target.name;
        let value = e.target.value;
        let Profile = this.state.Profile;
        if (e.target.type === 'number' && !(name === 'rel_min_exp' || name === 'min_exp') && parseInt(value) <= 0) {
            value = ''
            e.target.value = ''
        }

        Profile[name] = value;
        await this.setState({ 'Profile': Profile });
    }

    handleSubmitProfile = async (e) => {
        e.preventDefault();
        let Profile = this.state.Profile;

        Profile['auth_key'] = localStorage.getItem('token');
        this.setState({ 'Profile': Profile });
        if (this.props.validator.allValid()) {
            await store.dispatch(postServices(this.state.Profile));
            await this.props.validator.hideMessages()
            this.setState({ 'Profile': [] });
            document.getElementById("file-input").value = ''
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }

    }

    render() {
        const { serviceList } = this.state
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        let Mainprops = this.props
        const { validator } = this.props

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col-md-10 second-side py-2 pl-4 h-100 m-0 mb-3">
                            <div className="clearfix"></div>
                            <div className="tab-content">
                                <div id="home" className=" tab-pane active">
                                    <section className="login-form bg-education mt-2 p-0">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-12  mx-auto   p-0">
                                                    <div className="panel-heading">
                                                        <h6 className="mb-0">{this.props.t('lsp_user.post_services_title', { param: 'react' })}</h6>
                                                    </div>
                                                    <div className="panel-body m-0 p-0">
                                                        <form onSubmit={this.handleSubmitProfile} >
                                                            <div className="bg-education p-3">
                                                                <div className="row">
                                                                    <div
                                                                        className="col-sm-12 col-md-12 col-lg-12 mt-3 ">
                                                                        <label>{this.props.t('lsp_user.service_cat', { param: 'react' })}
                                                                            <span style={{ color: 'red' }} className="ml-2">*</span></label>
                                                                        <hr className={"mt-1"} />
                                                                        <select className="form-control"
                                                                            value={this.state.Profile.category_id ? this.state.Profile.category_id : ""}
                                                                            onChange={this.handleProfileInput} name="category_id">
                                                                            <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('lsp_user.service_cat', { param: 'react' })}</option>
                                                                            {this.props.LspReducer.serviceCategory && this.props.LspReducer.serviceCategory.length > 0 &&
                                                                                this.props.LspReducer.serviceCategory.map(function (data, index) {
                                                                                    return (
                                                                                        <option key={index} value={data.category_id}>{data.category_name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                        {validator.message('category name', this.state.Profile.category_id, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div
                                                                        className="col-sm-12 col-md-12 col-lg-12 mt-3 ">
                                                                        <label>{this.props.t('lsp_user.service_name', { param: 'react' })}
                                                                            <span style={{ color: 'red' }} className="ml-2">*</span></label>
                                                                        <hr className={"mt-1"} />
                                                                        <select className="form-control"
                                                                            value={this.state.Profile.service_id ? this.state.Profile.service_id : ""}
                                                                            onChange={this.handleProfileInput} name="service_id">
                                                                            <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('lsp_user.service_name', { param: 'react' })}</option>
                                                                            {serviceList && serviceList.length > 0 &&
                                                                                serviceList.map(function (data, index) {
                                                                                    return (
                                                                                        <option key={index} value={data.id}>{data.services_name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                        {validator.message('service name', this.state.Profile.service_id, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                                                                                <label>{this.props.t('lsp_user.price_tag', { param: 'react' })}
                                                                                <span style={{ color: 'red' }} className="ml-2">*</span></label>
                                                                                <input className="d-block" id="file-input" accept={this.docFormats.toString()}
                                                                                    type="file"
                                                                                    onChange={(e) => this.handleProfileImage(e, 'error')} />
                                                                                    {validator.message('price tag', this.state.Profile.img, 'required')}
                                                                                    
                                                                                {this.state.error && <p style={{
                                                                                    "color": "red",
                                                                                }}>{this.state.error}</p>}
                                                                                <span className={'edit-img d-block'}>
                                                                                    {this.props.t('profileDetails.uploadInst', { param: 'react' })} </span>
                                                                                <span className="date-clr">{this.props.t('lsp_user.service_inst', { param: 'react' })}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className=" col-sm-12 col-lg-12">
                                                                        <hr />
                                                                        <button type="submit"
                                                                            className="btn btn-primary mx-auto d-block btn-primary2 mt-1">{this.props.t('JobPost.submit', { param: 'react' })}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Validation(PostService)));
