
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import {
    postJob, get_genders, get_Salary_Type, jobPosted,
} from '../../../actions/jobpostAction';
import {
    get_User_Coursenature,
    get_User_District,
    get_skills,
    getEducationLevel
} from '../../../actions/accountAction';
import {
    getTimeSlots, getJobProviderAddress, get_jobprovider_profile
} from '../../../actions/employerAction';
import {
    getDisability
} from '../../../actions/jobseekerAction';
import store from '../../../store';
import Sidebar from './Sidebar';
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css"
// import CKEditor from 'ckeditor4-react';
import Validation from "../../hoc/Validation";
// import DatePicker from "react-datepicker";
import moment from "moment";
import { Multiselect } from 'multiselect-react-dropdown';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Link } from 'react-router'

class PostJob extends Component {

    constructor(props) {
        super(props);
        this.myRefDesc = React.createRef();
        this.myRefQual = React.createRef();
        this.multiselectRef = React.createRef()
        this.state = {
            submitStatus: false,
            // errorDateFrom: '',
            // errorDateTo: '',
            errorTime: false,
            user_subtype: window.location.protocol + '//' + window.location.host + '/',
            Profile: [],
            candidates: [],
            otherMsg: '',
            multiSelect: [],
            valid: false,
            error: "",
            // errorQualification: "",
            // errorDesc: '',
            stateId: '',
            errorSalary: '',
            errorExp: '',
            errorRelExp: '',
            skillData: [],
            showDistrict: false,
            errorAge: '',
            errorMaxAge: '',
            errorMinExp: '',
            errorAgeMax: '',
            rememberCheckboxes: new Set(),
            changed: false,
            selectedDays: [],
            dates: [],
            selectionLimit: -1,
            selectionLimitdist: -1
        }



        store.dispatch(get_User_Coursenature());
        store.dispatch(get_Salary_Type());
        store.dispatch(get_genders());
        store.dispatch(get_User_District());
        store.dispatch(get_skills());
        store.dispatch(getEducationLevel());
        store.dispatch(getTimeSlots());
        store.dispatch(getJobProviderAddress())
        store.dispatch(get_jobprovider_profile());
        store.dispatch(getDisability());
    }

    // onChange = (evt) => {
    //     var newContent = evt.editor.getData();
    //     if (newContent) {
    //         if (this.state.errorDesc) {
    //             this.setState({ errorDesc: '' })
    //         }
    //         let Profile = this.state.Profile;
    //         Profile['description'] = newContent;
    //         this.setState({ 'Profile': Profile });
    //     }
    // }

    handleDayClick = (day, { selected }) => {
        const { selectedDays } = this.state;

        const after = moment(day).format('YYYY-MM-DD')
        const before = moment().format('YYYY-MM-DD')

        const oldDates = this.state.dates
        // if(selectedDays.le)
        if (selected) {
            // console.log('1')
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
            oldDates.splice(selectedIndex, 1);
            this.setState({ dates: oldDates })
        } else if (moment(after).isAfter(before)) {
            // console.log('2')
            selectedDays.push(day);
            const dates = []
            dates.push({
                date: moment(day).format('YYYY-MM-DD'),
                start_time: "",
                end_time: "",
                error_start_time: "",
                error_end_time: ""
            })
            this.setState({ dates: [...oldDates, ...dates] })
        }
        this.setState({ selectedDays });
    }



    getformatedDates = () => {
        const selectedDays = this.state.selectedDays;
        const oldDates = this.state.dates
        const dates = []
        if (selectedDays && selectedDays.length) {
            selectedDays.forEach((d) => {
                dates.push({
                    date: moment(d).format('YYYY-MM-DD'),
                    start_time: "",
                    end_time: "",
                    error_start_time: "",
                    error_end_time: ""
                })
            })

            // return 
        }
        this.setState({ dates: [...oldDates, ...dates] })
    }



    componentDidMount() {
        document.title = this.props.t('sidebar.post_job_vacancies', { param: 'react' })
    }


    setDefaults = () => {
        const Profile = this.state.Profile
        Profile['gender_preference'] = "4"
        Profile['salary_type'] = "2"
        Profile['expType'] = "1"
        Profile['disability'] = "0"
        Profile['disablity_type_id'] = "28"


        this.setState({ Profile })
    }

    componentWillUnmount() {
        store.dispatch(jobPosted(false))
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setDefaults()

        if (nextProps.EmployerReducer.Profile && Object.keys(nextProps.EmployerReducer.Profile).length &&
            nextProps.EmployerReducer.address && nextProps.EmployerReducer.address[0] && !this.state.changed) {
            let Profile = this.state.Profile
            const address = nextProps.EmployerReducer.address[0]
            const profileData = nextProps.EmployerReducer.Profile
            Profile['address_Line1'] = address ? address.address_Line1 : ''
            Profile['address_Line2'] = address ? address.address_Line2 : ''
            Profile['address_Line3'] = address ? address.address_Line3 : ''
            Profile['contact_person'] = profileData ? profileData.c_person : ''
            Profile['person_designation'] = profileData ? profileData.c_desg : ''
            Profile['contact_no'] = profileData ? profileData.c_phone : ''
            Profile['city'] = address ? address.city : ''
            this.setState({ Profile, changed: true })
        }


        if (nextProps.JobpostReducer.jobPosted) {
            this.props.router.push('/myaccount')
        }
    }

    clearValue = (value, e) => {
        e.persist()
        setTimeout(() => {
            value = ''
            e.target.value = ''
        }, 200)
    }

    resetValues() {
        // By calling the belowe method will reset the selected values programatically
        this.multiselectRef.current.resetSelectedValues();
        // if(this.multiselectRef && this.multiselectRef.current){
        // this.multiselectRef.current.resetSelectedValues();
        // console.log('this.multiselectRef.current.getSelectedItems()', this.multiselectRef.current.getSelectedItems())
        // }

        // this.multiselectRef.current.getSelectedItems();
        // this.multiselectRef.current.getSelectedItemsCount();
    }

    // setTime = (value) => {
    //     const timeSlot = 0.45;
    //     let newValue = parseFloat(value.replace(':', '.'))


    //     newValue = newValue + timeSlot
    //     console.log('return newValue.toString().replace', newValue)
    //     console.log('return newValue.toString().replace', typeof newValue)
    //     // return newValue.toString().replace('.', ':')

    //     // console.log('return newValue.toString().replace', typeof newValue.toString())
    //     // return String(newValue).replace('.', ':')
    // }

    handleChangeTimeInput = (e, key) => {
        const name = e.target.name
        const value = e.target.value
        // console.log('EVENT', parseFloat(value))
        let newValue = value.replace(':', '.')
        // console.log('newValue', newValue)
        let dates = this.state.dates;
        let lunchstart = 13.30;
        let minutes = 0;
        if (this.state.Profile.time_slot == 1) {
            lunchstart = 13.15;
            minutes = 15;
        }
        else if (this.state.Profile.time_slot == 2) {
            lunchstart = 13.00;
            minutes = 30;
        }
        else if (this.state.Profile.time_slot == 3) {
            lunchstart = 12.45;
            minutes = 45;
        }
        else if (this.state.Profile.time_slot == 4) {
            lunchstart = 12.30;
            minutes = 60;
        }
        if ((value === '13:30' || (parseFloat(newValue) > lunchstart && parseFloat(newValue) <= 14.30)) &&
            (name === 'start_time' || name === 'end_time')) {
            dates[key]['start_time'] = ""
            dates[key]['end_time'] = ""
        } else {
            dates[key][name] = value
        }


        if (!value && name === 'start_time') {
            dates[key]['error_start_time'] = `Start time is required`

        }
        if ((value === '13:30' || (parseFloat(newValue) > lunchstart && parseFloat(newValue) <= 14.30)) && (name === 'start_time' || name === 'end_time')) {
            dates[key]['error_start_time'] = `Cannot select time between 1:30 PM to 2:30 PM`
        }
        else if (dates[key]['error_start_time'] !== "" && name === 'start_time') {
            dates[key]['error_start_time'] = ""
            this.setState({ errorTime: false })
        }
        let starttime = dates[key]['start_time'].match(/(\d+)(?::(\d\d))?\s*(p?)/);
        let endtime = dates[key]['end_time'].match(/(\d+)(?::(\d\d))?\s*(p?)/);
        if (value && starttime && endtime) {
            console.log(starttime);
            var startdate = new Date();
            startdate.setHours(parseInt(starttime[1]) + (starttime[3] ? 12 : 0));
            startdate.setMinutes(parseInt(starttime[2]) || 0);
            startdate.setMinutes(startdate.getMinutes() + minutes);
            var enddate = new Date();
            enddate.setHours(parseInt(endtime[1]) + (endtime[3] ? 12 : 0));
            enddate.setMinutes(parseInt(endtime[2]) || 0);
            console.log(startdate, enddate);
            if (startdate > enddate) {
                dates[key]['error_start_time'] = 'Cannot select time before ' + startdate.getHours() + ':' + startdate.getMinutes();
            }
            else {
                dates[key]['error_start_time'] = ""
                this.setState({ errorTime: false })
            }
        }
        if (!value && name === 'end_time') {
            dates[key]['error_end_time'] = `End time is required`
        } else if (dates[key]['error_end_time'] !== "" && name === 'end_time') {
            dates[key]['error_end_time'] = ""
            this.setState({ errorTime: false })
        }
        this.setState({ dates })
    }

    checkExp = (e) => {
        let name = e.target.name
        let value = e.target.value
        let Profile = this.state.Profile
        if (Profile.expType === '2') {
            if (name === 'max_exp' && parseFloat(value) <= parseFloat(Profile.min_exp)) {
                this.setState({ errorExp: "Max experience must be greater than min experience" })
                this.clearValue(value, e)
            } else {
                this.setState({ errorExp: "" })
            }
        }
    }

    checkMinExp = (e) => {
        let name = e.target.name
        let value = e.target.value
        let Profile = this.state.Profile
        if (Profile.expType === '2') {
            if (name === 'min_exp' && !value) {
                this.setState({ errorMinExp: "Min experience is required" })
                // this.clearValue(value, e)
            } else {
                this.setState({ errorMinExp: "" })
            }
        }
    }


    checkRelExp = (e) => {
        let name = e.target.name
        let value = e.target.value
        let Profile = this.state.Profile
        if (name === 'rel_max_exp' && parseFloat(value) <= parseFloat(Profile.rel_min_exp)) {
            this.setState({ errorRelExp: "Max rel. experience must be greater than min rel experience" })
            this.clearValue(value, e)
        } else {
            this.setState({ errorRelExp: "" })
        }
    }

    checkAge = (e) => {
        let name = e.target.name
        let value = e.target.value
        let Profile = this.state.Profile
        if (name === 'max_age' && parseFloat(value) <= parseFloat(Profile.age_preference)) {
            this.setState({ errorAgeMax: "Max age must be greater than age preference" })
            this.clearValue(value, e)
        } else {
            this.setState({ errorAgeMax: "" })
        }
    }

    checkSalary = (e) => {
        let name = e.target.name
        let value = e.target.value
        let Profile = this.state.Profile
        if (name === 'salary_max' && parseFloat(value) <= parseFloat(Profile.salary_min)) {
            this.setState({ errorSalary: "Max salary must be greater than min salary" })
            this.clearValue(value, e)
        } else {
            this.setState({ errorSalary: "" })
        }
    }

    handleJobInput = async (e) => {
        e.persist();
        const name = e.target.name;
        let value = e.target.value;

        let Profile = this.state.Profile;
        // if(type ==='number'){
        //     const regex = /^[A-Za-z0-9 ]+$/
        //     const isValid = regex.test(value);
        //     value = isValid ? value: ""
        // }
        if(name ==='disablity_type_id' && this.state.Profile.disablity_type_id === "28" ){
            return false
        }
        if (e.target.type === 'number' && value.length > 2 && name === 'age_preference') {
            const newVal = value.substring(0, 2)
            value = newVal
            e.target.value = newVal
        }
        if (name === 'state_id') {
            this.setState({ stateId: value })
        }

        if (name === 'age_preference') {
            const errorAge = parseInt(value) < 18 ? 'Age should be greater than equal to 18' : ''
            this.setState({ errorAge });
        }
        if (name === 'max_age') {
            const errorMaxAge = parseInt(value) < 18 ? 'Age should be greater than equal to 18' : ''
            this.setState({ errorMaxAge });
        }
        if (name === 'education_level') {
            delete Profile['qualification1']
            delete Profile['qualification2']
            delete Profile['qualification3']
            Profile['additional'] = false
            this.setState({ multiSelect: [] })
        }
        if (name === 'expType' && (!value || value === '1')) {
            // delete Profile['rel_min_exp']
            // delete Profile['rel_max_exp']
            delete Profile['min_exp']
            delete Profile['max_exp']
            this.setState({ errorExp: "" })
            this.setState({ errorMinExp: "" })
        }
        if (name === 'additional') {
            // console.log('e.target.checked', e.target.checked)
            value = e.target.checked
            if (!e.target.checked) {
                delete Profile['qualification1']
                delete Profile['qualification2']
                delete Profile['qualification3']
                // delete Profile['max_exp']
                this.setState({ multiSelect: [] })
            }
        }
        Profile[name] = value;
        await this.setState({ Profile });
        // console.log("2", this.state.Profile)
    }

    checkNumberFields = (Profile) => {

        const dates = this.state.dates
        const filter_date_start_time = dates.findIndex((d) => d.start_time === "")
        if (filter_date_start_time !== -1) {
            dates[filter_date_start_time]['error_start_time'] = "Start time is required"
            this.setState({ errorTime: true })
        }
        const filter_date_end_time = dates.findIndex((d) => d.end_time === "")
        if (filter_date_end_time !== -1) {
            dates[filter_date_end_time]['error_end_time'] = "End time is required"
            this.setState({ errorTime: true })
        }
        // if(dates && !dates)
        // const filter_error_start_time =  dates.findIndex((d) => date.error_start_time !=="")
        // const filter_error_end_time =  dates.findIndex((d) => date.error_end_time !=="")
        // if(filter_error_start_time){
        //     dates[]
        // }

        if (parseInt(Profile.salary_max) <= parseInt(Profile.salary_min)) {
            this.setState({ errorSalary: "Max salary must be greater than min salary" })
        } else {
            this.setState({ errorSalary: "" })
        }

        // if (Profile.expType ==='2' && Profile.max_exp && Profile.min_exp && parseInt(Profile.max_exp) <= parseInt(Profile.min_exp)) {
        //     console.log('ASDASDAS')
        //     this.setState({ errorExp: "Max experience must be greater than min experience" })
        // } else {
        //     this.setState({ errorExp: "" })
        // }
        // console.log('Profile.max_exp', Profile.max_exp)
        // console.log('Profile.min_exp', Profile.min_exp)
        if (Profile.expType === '2' && !Profile.max_exp) {
            this.setState({ errorExp: "Max experience field is required" })
        } else {
            this.setState({ errorExp: "" })
        }
        if (Profile.expType === '2' && !Profile.min_exp) {
            this.setState({ errorMinExp: "Min experience field is required" })
        } else {
            this.setState({ errorMinExp: "" })
        }
        if (parseInt(Profile.rel_max_exp) <= parseInt(Profile.rel_min_exp)) {
            this.setState({ errorRelExp: "Max rel. experience must be greater than min rel experience" })
        } else {
            this.setState({ errorRelExp: "" })
        }
        if (parseInt(Profile.rel_max_exp) <= parseInt(Profile.rel_min_exp)) {
            this.setState({ errorRelExp: "Max rel. experience must be greater than min rel experience" })
        } else {
            this.setState({ errorRelExp: "" })
        }
        if (parseInt(Profile.max_age) <= parseInt(Profile.age_preference)) {
            this.setState({ errorAgeMax: "Max age must be greater than age preference" })
        } else {
            this.setState({ errorAgeMax: "" })
        }
        if (parseInt(Profile.max_age) <= parseInt(Profile.age_preference)) {
            this.setState({ errorAgeMax: "Max age must be greater than age preference" })
        } else {
            this.setState({ errorAgeMax: "" })
        }

        // if (Profile.additional && !(Profile.qualification1 || Profile.qualification2 || Profile.qualification3)) {
        //     this.setState({ errorQualification: "Qualification field is required" })
        // } else {
        //     this.setState({ errorQualification: "" })
        // }
        if (this.state.Profile.state_id && this.state.Profile.state_id.length) {
            const fileterState = this.state.Profile.state_id.filter((obj) => obj.id === '3')
            console.log('fileterState', fileterState)
            console.log('Profile.location', Profile.location)
            if (fileterState && fileterState.length && (!Profile.location || !Profile.location.length)) {
                this.setState({ errorDistrict: "District field is required" })
            } else {
                this.setState({ errorDistrict: "" })
            }
        }
    }

    // handleChangeDate = (date, key) => {
    //     let formatedDate = moment(date).format('YYYY-MM-DD').split("-")
    //     if (formatedDate[0].length === 4) {
    //         const Profile = this.state.Profile
    //         formatedDate = `${formatedDate[0].substring(0, 4)}-${formatedDate[1]}-${formatedDate[2]}`
    //         Profile[key] = formatedDate
    //         this.setState({ Profile });
    //     }
    //     console.log("Date", this.state.Profile)
    // }

    // handleChangeDate = (date, key) => {
    //     const Profile = this.state.Profile
    //     let formatedDate = moment(date).format('YYYY-MM-DD').split("-")

    //     let errorDateTo = ''
    //     let errorDateFrom = ''
    //     if (key === 'slot_date_start' && Profile.slot_date_end) {
    //         let newFormatedDate = `${formatedDate[0].substring(0, 4)}-${formatedDate[1]}-${formatedDate[2]}`
    //         if (moment(newFormatedDate).isSameOrAfter(Profile.slot_date_end)) {
    //             errorDateFrom = 'Start date must not be greater than equal to the end date'
    //         } else {
    //             errorDateFrom = ''
    //             this.setDatePickerValues(formatedDate, key)
    //         }
    //         this.setState({ errorDateFrom })

    //     } else if (key === 'slot_date_end' && Profile.slot_date_start) {
    //         let newFormatedDate = `${formatedDate[0].substring(0, 4)}-${formatedDate[1]}-${formatedDate[2]}`

    //         if (moment(newFormatedDate).isSameOrBefore(Profile.slot_date_start)) {
    //             errorDateTo = 'End date must not be less than or equal to the start date'
    //         } else {
    //             this.setDatePickerValues(formatedDate, key)
    //             errorDateTo = ''
    //         }
    //         this.setState({ errorDateTo })

    //     } else {
    //         this.setDatePickerValues(formatedDate, key)
    //     }

    // }

    setDatePickerValues = (formatedDate, key) => {
        const Profile = this.state.Profile
        if (formatedDate[0].length === 4) {
            formatedDate = `${formatedDate[0].substring(0, 4)}-${formatedDate[1]}-${formatedDate[2]}`
            Profile[key] = formatedDate
            this.setState({ Profile });
        }
    }

    handleUserInput(e) {
        let Profile = this.state.Profile;
        const name = e.target.name;
        const value = e.target.value;
        if(parseInt(value)) {
            delete Profile['disablity_type_id']
        } else {
            Profile['disablity_type_id'] = '28'
        }
        Profile[name] = value
        this.setState({ Profile });
    }


    handleSubmitProfile = async (e) => {
        e.preventDefault();
        let Profile = this.state.Profile;
        await this.checkNumberFields(Profile)
        const { errorRelExp, errorExp, errorSalary, errorAge, errorMaxAge, errorMinExp,
            errorAgeMax,
            // errorDateTo, errorDateFrom,
            errorDistrict,
            errorTime } = this.state
        Profile['auth_key'] = localStorage.getItem('token');
        Profile['nature_of_job'] = "1"
        Profile['interview_details'] = this.state.dates
        this.setState({ Profile });

        // console.log('this.props.validator.allValid()', this.props.validator.allValid())
        // console.log('this.props.validator.allValid()', this.state)
        // if ((this.state.valid || this.props.validator.allValid()) && Profile.description &&
        //     (Profile.qualification1 || Profile.qualification2 || Profile.qualification3) &&
        //     !errorRelExp && !errorExp && !errorSalary && !errorAge && !errorMaxAge && !errorAgeMax &&
        //     !errorDateTo && !errorDateFrom) {
        // console.log('this.props.validator.allValid()', this.props.validator)
        // console.log('this.props.validator.allValid()', this.props.validator.allValid())
        if (this.props.validator.allValid() && Profile.description &&
            // (Profile.qualification1 || Profile.qualification2 || Profile.qualification3) &&
            !errorRelExp && !errorExp && !errorSalary && !errorAge && !errorMaxAge && !errorAgeMax
            && !errorDistrict && !errorMinExp && !errorTime) {

            await store.dispatch(postJob(this.state.Profile));
            await this.props.validator.hideMessages()
            // this.setState({ errorDesc: '' })
            await this.setState({ 'Profile': [], selectedDays: [], dates: [] });
            await this.setDefaults()
            await this.setState({ content: '' });
            await this.setState({ multiSelect: [] });
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }

    }

    changeCheckbox = (e) => {
        this.handleJobInput(e)
    }


    handleChange = (multiSelect) => {
        let Profile = this.state.Profile;
        if (Profile.additional) {
            // if (multiSelect && multiSelect.length) {
            if (!multiSelect.length) {
                delete Profile['qualification1']
                delete Profile['qualification2']
                delete Profile['qualification3']
            }


            // console.log('multiSelect', multiSelect)
            multiSelect.length && multiSelect.forEach((select, key) => {
                Profile[`qualification${key + 1}`] = select.id;
                this.setState({ 'Profile': Profile });
            })
            // console.log('this', this.state.Profile)
            // } 
            // else 
        }
    }

    handleCandidates = (candidates) => {
        if (candidates && candidates.length) {
            let job_id = candidates[candidates.length - 1].jobId
            this.setState({ candidates, job_id });
        }

    }


    changeRegister = (event) => {
        window.location.href = this.state.basePath + 'signup?type=' + event;
    }


    createState = () => {
        let Mainprops = this.props;
        let Distoption = []
        if (Mainprops.actionReducer.District.length > 0) {
            Mainprops.actionReducer.District.forEach(function (data, index) {
                Distoption.push(<option key={index} value={data.state_id}>{data.state_name}</option>)
            })
            return Distoption
        }
    }


    createDistoption = () => {
        let Mainprops = this.props;
        let Distoption = []
        if (Mainprops.actionReducer.District && Mainprops.actionReducer.District.length > 0) {
            Mainprops.actionReducer.District.map((data, index) => {
                if (data.state_id === "3") {
                    data.dist.map(function (distdata, distindex) {
                        Distoption.push({ id: distdata.id, label: distdata.name })
                        return Distoption
                    })
                }
                return Distoption
            })
        }
        return Distoption
    }


    handleChangeState = (multiSelectState) => {
        let Profile = this.state.Profile
        Profile.state_id = multiSelectState
        const filterState1 = multiSelectState.filter((state) => state.id === '37')
        if (filterState1 && filterState1[0]) {
            Profile.state_id = filterState1;
            this.setState({ Profile, selectionLimit: 1, showDistrict: false });
            return;
        }
        const filterState = multiSelectState.filter((state) => state.id === '3')
        if (filterState && filterState[0]) {
            this.setState({ showDistrict: true })
        } else {
            this.setState({ showDistrict: false, errorDistrict: "" })
            delete Profile['location']
        }
        this.setState({ Profile, selectionLimit: -1 })
    }

    // handleChangeState = async(selectedList) => {
    //     const Profile = this.state.Profile
    //     Profile['location'] = []
    //     this.setState({Profile})
    //     await this.resetValues()
    //     let filterDist1 = selectedList.filter((dist) => dist.id === '667')
    //  if(filterDist1 && filterDist1.length){
    //      Profile['location'] = this.getDistrictId(filterDist1)
    //         this.setState({Profile})
    //      return
    //  }

    //  let filterDist2 = selectedList.filter((dist) => dist.id === '666')
    //  if(filterDist2 && filterDist2.length){
    //      Profile['location'] = this.getDistrictId(filterDist2)
    //      this.setState({Profile})
    //      return
    //  }
    //  Profile['location'] = this.getDistrictId(selectedList)
    //  this.setState({Profile})
    // }

    getDistrictId = (selectedList) => {
        let district = []
        selectedList.length && selectedList.forEach((dist) => {
            district.push(dist.id)
        })
        return district
    }

    handleChangeDistrict = (multiSelectState) => {
        if (this.state.Profile.state_id && this.state.Profile.state_id.length) {
            const fileterState = this.state.Profile.state_id.filter((obj) => obj.id === '3')
            if (fileterState && fileterState.length) {
                this.setState({ errorDistrict: '' })
                console.log(multiSelectState);
                if (!multiSelectState.length) {
                    this.setState({ errorDistrict: 'District field is required' })
                }
                let Profile = this.state.Profile;
                let filterDist2 = multiSelectState.filter((dist) => dist.id === '666')
                let filterDist3 = multiSelectState.filter((dist) => dist.id === '667')
                if (filterDist3 && filterDist3[0]) {
                    Profile['location'] = filterDist3
                    this.setState({ Profile })
                    return
                }
                if (filterDist2 && filterDist2[0]) {
                    Profile['location'] = filterDist2
                    this.setState({ Profile, selectionLimitdist: 1 })
                    return
                }
                Profile['location'] = multiSelectState;
                this.setState({ Profile, selectionLimitdist: -1 })
                // console.log('Profiule', this.state.Profile)


            }
        }


    }


    districtClicked = () => {
        if (!this.state.Profile.state_id) {
            this.setState({ error: 'Please select state first' })
            document.getElementById("state_id").focus()
            setTimeout(() => {
                this.setState({ error: '' })
            }, 2000)
        }
    }


    render() {

        const { Profile,
            // errorDesc,
            showDistrict, errorAge,
            // errorMaxAge,errorAgeMax, 
            // errorDateTo, errorDateFrom,
            dates,
            errorDistrict, errorMinExp } = this.state
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        let Mainprops = this.props
        const data = []
        const educationLevel = this.props.actionReducer.educationLevel
        if (Profile.education_level) {
            const selectedEducation = educationLevel.filter((edu) => edu.education_id === Profile.education_level)
            if (selectedEducation && selectedEducation[0]) {
                selectedEducation && selectedEducation[0] && selectedEducation[0].qualification.forEach((qual) => {
                    data.push({ label: qual.name, id: qual.id })
                })
            }
        }
        const stateData = []
        if (Mainprops.actionReducer.District && Mainprops.actionReducer.District.length) {
            Mainprops.actionReducer.District.forEach((qual) => {
                stateData.push({ label: qual.state_name, id: qual.state_id })
            })
        }

        const distData = this.createDistoption()
        const { validator } = this.props

        //  console.log('interviewDates', this.state.Profile.location)
        // console.log('interviewDates', dates)
        // console.log('selectedDays', this.state.selectedDays)
        // console.log('Mainprops.EmployerReducer.timeSlot', Mainprops.EmployerReducer.timeSlot)
        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col-md-10 second-side py-2 pl-4 h-100 m-0 mb-3">
                            <div className="clearfix"></div>
                            <div className="tab-content">
                                <div id="home" className=" tab-pane active">
                                    <section className="login-form bg-education mt-2 p-0">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-12  mx-auto   p-0">
                                                    <div className="panel-heading">
                                                        <h6 className="mb-0">{this.props.t('sidebar.post_job_vacancies', { param: 'react' })}</h6>
                                                    </div>

                                                     <div className="panel-body m-0 p-0">
                                                        <form onSubmit={this.handleSubmitProfile}>
                                                            <div className="bg-education p-3">
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-12 col-lg-12 mb-1 form-group  ">
                                                                        <div className="row">
                                                                            <div className="col-sm-12 col-md-12 col-lg-12 mt-3 mb-4">
                                                                                <label>{this.props.t('JobPost.title', { param: 'react' })}</label>
                                                                                <span
                                                                                    style={{ color: 'red' }}
                                                                                    className="ml-2">*</span>
                                                                                <hr className={"mt-1"} />


                                                                                <input onChange={this.handleJobInput}
                                                                                    type="text"
                                                                                    value={this.state.Profile.job_title ? this.state.Profile.job_title : ''}
                                                                                    className="form-control"
                                                                                    name="job_title"
                                                                                    placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('JobPost.title', { param: 'react' })} />
                                                                                {validator.message('job title', this.state.Profile.job_title, 'required')}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-12 col-lg-12 form-group">
                                                                        <div className="row" >
                                                                            <div
                                                                                className="col-sm-12 col-md-12 col-lg-12 mt-0 mb-4" ref={this.myRefDesc}>
                                                                                <label>{this.props.t('JobPost.desc', { param: 'react' })}</label><span
                                                                                    style={{ color: 'red' }} className="ml-2">*</span>
                                                                                <hr className={"mt-1"} />
                                                                                <textarea
                                                                                    className="w-100"
                                                                                    onChange={this.handleJobInput}
                                                                                    value={this.state.Profile.description ? this.state.Profile.description : ''}
                                                                                    name="description"
                                                                                    placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('JobPost.desc', { param: 'react' })} />

                                                                                {validator.message('description', this.state.Profile.description, 'required|max:200')}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-sm-12 mb-0 col-lg-12 form-group text-center">
                                                                        <label className="form-check-label panelbtn" htmlFor="check1">
                                                                            <a className={"date-clr"}
                                                                                data-toggle="collapse" href="#my-panel1"
                                                                            >{this.props.t('header.job_details', { param: 'react' })}</a>

                                                                        </label>
                                                                    </div>
                                                                    <div id="my-panel1" className="collapse w-100">
                                                                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                                                                            <div className="row">
                                                                                <div
                                                                                    className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                    <label>{this.props.t('JobPost.jobLocation', { param: 'react' })}</label><span
                                                                                        style={{ color: 'red' }}
                                                                                        className="ml-2">*</span>
                                                                                </div>
                                                                                <div
                                                                                    className="col-sm-4 col-lg-4 form-group">

                                                                                    {stateData && stateData.length ? <Multiselect
                                                                                        ref={this.multiselectRef}
                                                                                        options={stateData}
                                                                                        showCheckbox={true}
                                                                                        selectionLimit={this.state.selectionLimit}
                                                                                        selectedValues={this.state.Profile.state_id}
                                                                                        displayValue='label'
                                                                                        onSelect={this.handleChangeState}
                                                                                        onRemove={this.handleChangeState}
                                                                                    />
                                                                                        : null}
                                                                                    {validator.message('state', this.state.Profile.state_id, 'required')}
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        {distData && distData.length && showDistrict &&
                                                                            <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                        <label>{this.props.t('JobPost.district', { param: 'react' })}</label>
                                                                                        {showDistrict && <span
                                                                                            style={{ color: 'red' }}
                                                                                            className="ml-2">*</span>}
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-sm-4 col-lg-4 form-group">
                                                                                        {distData && distData.length ? <Multiselect
                                                                                            showCheckbox={true}
                                                                                            selectionLimit={this.state.selectionLimitdist}
                                                                                            options={!showDistrict ? [] : distData}
                                                                                            selectedValues={this.state.Profile.location}
                                                                                            displayValue='label'
                                                                                            onSelect={this.handleChangeDistrict}
                                                                                            onRemove={this.handleChangeDistrict}
                                                                                        /> : null}

                                                                                        {errorDistrict && <span
                                                                                            style={{ "color": "red" }}>{errorDistrict}</span>}

                                                                                    </div>
                                                                                </div>
                                                                            </div>}


                                                                        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                                            <div className="row">
                                                                                <div className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                    <label
                                                                                    >{this.props.t('JobPost.wage1', { param: 'react' })}</label><span
                                                                                        style={{ color: 'red' }}
                                                                                        className="ml-2">*</span>
                                                                                </div>
                                                                                <div
                                                                                    className="col-sm-4 col-lg-4 form-group ">
                                                                                    <select
                                                                                        value={this.state.Profile.salary_type}
                                                                                        onChange={this.handleJobInput}
                                                                                        className="form-control"
                                                                                        id="salary_type" name="salary_type">
                                                                                        <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('JobPost.wage1', { param: 'react' })}
                                                                                        </option>
                                                                                        {Mainprops.JobpostReducer.salaryType.length > 0 &&
                                                                                            Mainprops.JobpostReducer.salaryType.map(function (data, index) {
                                                                                                return (
                                                                                                    <option value={data.id}
                                                                                                        key={index}>{data.name}</option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                    {validator.message('salary type', this.state.Profile.salary_type, 'required')}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                                                                            <div className="row">
                                                                                <div className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                    <label>{this.props.t('JobPost.sal', { param: 'react' })}</label><span
                                                                                        style={{ color: 'red' }}
                                                                                        className="ml-2">*</span>
                                                                                </div>

                                                                                <div className="col-sm-4 col-lg-4  text-left form-group mb-1">
                                                                                    <input onChange={this.handleJobInput}
                                                                                        value={this.state.Profile.salary_min ? this.state.Profile.salary_min : ''}
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                        name="salary_min"
                                                                                        placeholder={this.props.t('JobPost.sal', { param: 'react' })}
                                                                                        min="1" />
                                                                                    <label className="mb-0 mr-1">{this.props.t('JobPost.min', { param: 'react' })}</label>
                                                                                    {validator.message('min salary', this.state.Profile.salary_min, 'required|numeric')}
                                                                                    <label
                                                                                        className=""
                                                                                        style={{ 'fontSize': '12px', 'color': '#f7900d' }}>{this.props.t('JobPost.inr', { param: 'react' })}</label>
                                                                                </div>
                                                                                <div className="col-sm-4 col-lg-4 offset-md-2 text-left"></div>
                                                                                <div className="col-sm-4 col-lg-4 text-left form-group mb-1">
                                                                                    <input onChange={this.handleJobInput}
                                                                                        value={this.state.Profile.salary_max ? this.state.Profile.salary_max : ''}
                                                                                        onBlur={(e) => this.checkSalary(e)}
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                        name="salary_max"
                                                                                        placeholder={this.props.t('JobPost.sal', { param: 'react' })}
                                                                                        min="1" />

                                                                                    <label className="mb-0 mr-1">{this.props.t('JobPost.max', { param: 'react' })}</label>
                                                                                    {validator.message('max salary', this.state.Profile.salary_max, 'required|numeric')}
                                                                                    {this.state.errorSalary && <span
                                                                                        style={{ "color": "red" }}>{this.state.errorSalary}</span>}
                                                                                    <label className="" style={{ 'fontSize': '12px', 'color': '#f7900d' }}>{this.props.t('JobPost.inr', { param: 'react' })}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                                            <div className="row">
                                                                                <div className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                    <label>{this.props.t('JobPost.expType', { param: 'react' })}<span
                                                                                        style={{ color: 'red' }}
                                                                                        className="ml-2">*</span></label>
                                                                                </div>
                                                                                <div className="col-sm-4 col-lg-4 text-left form-group">
                                                                                    <select className="form-control"
                                                                                        onChange={this.handleJobInput}
                                                                                        value={this.state.Profile.expType ? this.state.Profile.expType : ''}
                                                                                        id="expType"
                                                                                        name="expType">
                                                                                        <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('JobPost.expType', { param: 'react' })}</option>
                                                                                        <option value="1">{this.props.t('JobPost.fresher', { param: 'react' })}</option>
                                                                                        <option value="2">{this.props.t('JobPost.experienced', { param: 'react' })}</option>
                                                                                    </select>
                                                                                    {validator.message('experience type', this.state.Profile.expType, 'required')}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.Profile.expType && this.state.Profile.expType === '2' &&
                                                                            <div className="col-sm-12 col-md-12 col-lg-12 ">
                                                                                {this.state.Profile.expType && this.state.Profile.expType === '2' &&

                                                                                    <div className="row">
                                                                                        <div className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                            <label
                                                                                            >{this.props.t('JobPost.experience', { param: 'react' })}</label><span
                                                                                                style={{ color: 'red' }}
                                                                                                className="ml-2">*</span>
                                                                                        </div>

                                                                                        <div className="col-sm-4 form-group">
                                                                                            <input type="number"
                                                                                                value={this.state.Profile.min_exp ? this.state.Profile.min_exp : ''}
                                                                                                onChange={this.handleJobInput}
                                                                                                className="form-control"

                                                                                                onBlur={(e) => this.checkMinExp(e)}
                                                                                                name="min_exp"
                                                                                                placeholder={this.props.t('JobPost.experience', { param: 'react' })}
                                                                                            />
                                                                                            <label>{this.props.t('JobPost.min', { param: 'react' })}</label>

                                                                                            {errorMinExp && <label
                                                                                                style={{ "color": "red" }}>{errorMinExp}</label>}

                                                                                        </div> <div className="col-sm-4 col-lg-4 offset-md-2 text-left"></div>
                                                                                        <div className="col-sm-4 col-lg-4  text-left form-group">

                                                                                            <input type="number"
                                                                                                value={this.state.Profile.max_exp ? this.state.Profile.max_exp : ''}
                                                                                                className="form-control"
                                                                                                onBlur={(e) => this.checkExp(e)}
                                                                                                onChange={this.handleJobInput}
                                                                                                name="max_exp"
                                                                                                placeholder={this.props.t('JobPost.experience', { param: 'react' })}
                                                                                                min="1" />

                                                                                            <label>{this.props.t('JobPost.max', { param: 'react' })}</label>

                                                                                            {this.state.errorExp && <label
                                                                                                style={{ "color": "red" }}>{this.state.errorExp}</label>}
                                                                                        </div>
                                                                                    </div>
                                                                                }

                                                                            </div>
                                                                        }
                                                                        <div
                                                                            className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                                            <div className="row">
                                                                                <div className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                    <label>{this.props.t('JobPost.gender', { param: 'react' })} </label><span
                                                                                        style={{ color: 'red' }}
                                                                                        className="ml-2">*</span>
                                                                                </div>
                                                                                <div
                                                                                    className="col-sm-4 col-md-4 form-group ">
                                                                                    <select
                                                                                        value={this.state.Profile.gender_preference}
                                                                                        onChange={this.handleJobInput}
                                                                                        className="form-control"
                                                                                        name="gender_preference">
                                                                                        <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('JobPost.gender', { param: 'react' })}
                                                                                        </option>
                                                                                        {Mainprops.JobpostReducer.Genders.length > 0 &&
                                                                                            Mainprops.JobpostReducer.Genders.map(function (data, index) {
                                                                                                return (
                                                                                                    <option key={index}
                                                                                                        value={data.id}>{data.name}</option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        <option value="4">Any
                                                                                        </option>
                                                                                    </select>
                                                                                    {validator.message('gender preference', this.state.Profile.gender_preference, 'required')}
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12 col-md-12 col-lg-12 aruna">
                                                                            <div className="row">
                                                                                <div className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                    <label>{this.props.t('JobPost.vac', { param: 'react' })}</label><span
                                                                                        style={{ color: 'red' }}
                                                                                        className="ml-2">*</span>
                                                                                </div>
                                                                                <div
                                                                                    className="col-sm-4 col-lg-4 form-group ">
                                                                                    <input type="number"
                                                                                        value={this.state.Profile.vacancies ? this.state.Profile.vacancies : ''}
                                                                                        onChange={this.handleJobInput}
                                                                                        className="form-control"
                                                                                        name="vacancies"
                                                                                        placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('JobPost.vac', { param: 'react' })}
                                                                                        min="1" />
                                                                                    {validator.message('vacancies', this.state.Profile.vacancies, 'required|numeric')}
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                                                            <div className="row">
                                                                                <div className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                    <label >{this.props.t('govtJobs.age', { param: 'react' })} </label><span
                                                                                        style={{ color: 'red' }}
                                                                                        className="ml-2">*</span>
                                                                                </div>
                                                                                <div
                                                                                    className="col-sm-4 col-lg-4 form-group ">
                                                                                    <input type="number"
                                                                                        value={this.state.Profile.age_preference ? this.state.Profile.age_preference : ''}
                                                                                        onChange={this.handleJobInput}
                                                                                        className="form-control"
                                                                                        name="age_preference"
                                                                                        min="1"
                                                                                        placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('govtJobs.age', { param: 'react' })} />
                                                                                    {validator.message('age preference', this.state.Profile.age_preference, 'required|max:2')}
                                                                                    {errorAge && <span style={{ "color": "red" }}>{errorAge}</span>}
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                                                            <div className="row">
                                                                                <div className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                    <label > Job Posting Location </label><span
                                                                                        style={{ color: 'red' }}
                                                                                        className="ml-2">*</span>
                                                                                </div>
                                                                                <div
                                                                                    className="col-sm-4 col-lg-4 form-group ">
                                                                                    <input onChange={this.handleJobInput}
                                                                                        type="text"
                                                                                        value={this.state.Profile.place_of_posting ? this.state.Profile.place_of_posting : ''}
                                                                                        className="form-control"
                                                                                        name="place_of_posting"
                                                                                        placeholder={"Enter The Job Posting Location"} />
                                                                                    {validator.message('job posting', this.state.Profile.place_of_posting, 'required')}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                                                            <div className="row">
                                                                                <div className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                    <label >{this.props.t('JobPost.pwd', { param: 'react' })} </label>
                                                                                </div>
                                                                                <div
                                                                                    className="col-sm-4 col-lg-4 form-group ">
                                                                                    <div className="form-check-inline mr-0 pr-2">
                                                                                        <label className="form-check-label" htmlFor="radio44">
                                                                                            <input
                                                                                                onChange={(event) => this.handleUserInput(event)}
                                                                                                checked={this.state.Profile.disability === '1'}
                                                                                                type="radio" className="form-check-input"
                                                                                                id="radio2" name="disability"
                                                                                                value="1" />{this.props.t('profileDetails.yes', { param: 'react' })}
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check-inline mr-0 pr-0">
                                                                                        <label className="form-check-label" htmlFor="radio44">
                                                                                            <input
                                                                                                onChange={(event) => this.handleUserInput(event)}
                                                                                                checked={this.state.Profile.disability === '0'}
                                                                                                type="radio" className="form-check-input"
                                                                                                id="radio2" name="disability"
                                                                                                value="0" />{this.props.t('profileDetails.no', { param: 'react' })}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                                            <div className="row">
                                                                                <div className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                    <label>{this.props.t('profileDetails.disabilityType', { param: 'react' })}<span
                                                                                        style={{ color: 'red' }}
                                                                                        className="ml-2">*</span></label>
                                                                                </div>
                                                                                <div className="col-sm-4 col-lg-4 text-left form-group">
                                                                                    <select className="form-control"
                                                                                        onChange={this.handleJobInput}
                                                                                        disabled={this.state.Profile.disablity_type_id === "28"}
                                                                                        value={this.state.Profile.disablity_type_id ? this.state.Profile.disablity_type_id : ''}
                                                                                        name="disablity_type_id">
                                                                                        <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('profileDetails.disabilityType', { param: 'react' })}</option>
                                                                                        {Mainprops.JobseekerReducer.Disability.length > 0 &&
                                                                                            Mainprops.JobseekerReducer.Disability.map(function (data, index) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={index}
                                                                                                        value={data.id}>{data.name}</option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                    {validator.message('experience type', this.state.Profile.expType, 'required')}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">

                                                                    <div className="col-sm-12 col-lg-12 mb-0 form-group text-center mt-4">
                                                                        <label className="form-check-label panelbtn" htmlFor="check1">
                                                                            <a className={"date-clr"}
                                                                                data-toggle="collapse" href="#my-panel2"
                                                                            >{this.props.t('govtJobs.reqQual', { param: 'react' })}</a>

                                                                        </label>
                                                                    </div>
                                                                    <div id="my-panel2" className="collapse w-100">
                                                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                                                            <div className="row">
                                                                                <div className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                    <label>{this.props.t('JobPost.edu', { param: 'react' })}<span
                                                                                        style={{ color: 'red' }}
                                                                                        className="ml-2">*</span></label>
                                                                                </div>
                                                                                <div
                                                                                    className="col-sm-4 col-lg-4 form-group ">
                                                                                    <select
                                                                                        value={this.state.Profile.education_level ? this.state.Profile.education_level : ''}
                                                                                        onChange={this.handleJobInput}
                                                                                        className="form-control"
                                                                                        name="education_level">
                                                                                        <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('JobPost.edu', { param: 'react' })}
                                                                                        </option>
                                                                                        {Mainprops.actionReducer.educationLevel.length > 0 &&
                                                                                            Mainprops.actionReducer.educationLevel.map(function (data, index) {
                                                                                                return (
                                                                                                    <option
                                                                                                        value={data.education_id}
                                                                                                        key={index}>{data.education_name}</option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                    {validator.message('education level', this.state.Profile.education_level, 'required')}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.Profile.education_level ?
                                                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                        <label>{this.props.t('JobPost.additionalInfo', { param: 'react' })}
                                                                                            {/* <span
                                                                                    style={{ color: 'red' }}
                                                                                    className="ml-2">*</span> */}
                                                                                        </label>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-sm-4 col-lg-4 form-group ">
                                                                                        <input className="form-check-input ml-1" type="checkbox"
                                                                                            checked={this.state.Profile.additional}
                                                                                            name="additional" onChange={this.handleJobInput} />
                                                                                    </div>
                                                                                </div>
                                                                            </div> : null}

                                                                        {this.state.Profile.additional ?
                                                                            <div className="row p-3" ref={this.myRefQual}>
                                                                                <div
                                                                                    className="col-sm-12 col-md-12 col-lg-12 mt-3 ">
                                                                                    <label>{this.props.t('JobPost.qul', { param: 'react' })}
                                                                                    </label>
                                                                                    <hr className={"mt-1"} />

                                                                                    {data &&
                                                                                        <MultiSelect
                                                                                            items={data}
                                                                                            responsiveHeight={200}
                                                                                            maxSelectedItems={3}
                                                                                            selectedItems={this.state.multiSelect}
                                                                                            onChange={this.handleChange}
                                                                                        />}

                                                                                    {/* {errorQualification && <span
                                                                                        style={{ "color": "red" }}>{errorQualification}</span>} */}
                                                                                </div>
                                                                            </div> : null}
                                                                    </div>

                                                                </div>


                                                                {/* <div className="row">
                                                                    <div
                                                                        className="col-sm-12 col-md-12 col-lg-12 mt-3 ">
                                                                        <label>{this.props.t('JobPost.skill', { param: 'react' })}
                                                                            <span style={{ color: 'red' }}
                                                                                className="ml-2">*</span></label>
                                                                        <hr className={"mt-1"} />
                                                                        {skillData && skillData.length > 0 &&
                                                                            <Multiselect
                                                                                data={skillData}
                                                                                value={this.state.Profile.key_skills}
                                                                                textField='name'
                                                                                onChange={this.onChangeSkills}
                                                                            />
                                                                        }
                                                                        {validator.message('key skills', this.state.Profile.key_skills, 'required')}
                                                                    </div>
                                                                </div> */}



                                                                {/* <div className="row">
                                                                    <div className="col-sm-12 col-md-12 col-lg-6 ">
                                                                        <div className="row">
                                                                            <div className="col-sm-6 p-0 text-right">
                                                                                <label
                                                                                >{this.props.t('govtJobs.age', { param: 'react' })} </label><span
                                                                                    style={{ color: 'red' }}
                                                                                    className="ml-2">*</span>
                                                                            </div>
                                                                            <div
                                                                                className="col-sm-6 col-lg-6 form-group ">
                                                                                <input type="number"
                                                                                    value={this.state.Profile.max_age ? this.state.Profile.max_age : ''}
                                                                                    onChange={this.handleJobInput}
                                                                                    className="form-control"
                                                                                    onBlur={(e) => this.checkAge(e)}
                                                                                    name="max_age"
                                                                                    min="1"
                                                                                    placeholder={this.props.t('placeholder.enter', { param: 'react' }) +" "+this.props.t('govtJobs.age', { param: 'react' })}
                                                                                />
                                                                                {validator.message('max age', this.state.Profile.max_age, 'required|max:2')}
                                                                                {errorMaxAge && <span style={{ "color": "red" }}>{errorMaxAge}</span>}
                                                                                {errorAgeMax && <span style={{ "color": "red" }}>{errorAgeMax}</span>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}

                                                            </div>
                                                            <section className=" p-3">


                                                                {/* <div className="row">
                                                                    <div
                                                                        className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-sm-6 col-lg-6 text-right">
                                                                                <label>{this.props.t('JobPost.interviewSlotDateStart', { param: 'react' })}<span
                                                                                    style={{ color: 'red' }}
                                                                                    className="ml-2">*</span></label>
                                                                            </div>
                                                                            <div
                                                                                className="col-sm-6 col-lg-6 form-group">

                                                                                <DatePicker
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                    className="form-control"
                                                                                    showYearDropdown={true}
                                                                                    minDate={new Date()}
                                                                                    placeholderText="Enter interview slot start date"
                                                                                    showMonthDropdown={true}
                                                                                    selected={this.state.Profile.slot_date_start ? Date.parse(this.state.Profile.slot_date_start) : ''}
                                                                                    onChange={(e) => this.handleChangeDate(e, 'slot_date_start')}
                                                                                />
                                                                                {errorDateFrom && <span style={{ "color": "red" }}>{errorDateFrom}</span>}
                                                                                {validator.message('slot start date', this.state.Profile.slot_date_start, 'required')}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-sm-6 col-lg-6 text-right">
                                                                                <label>{this.props.t('JobPost.interviewSlotDateEnd', { param: 'react' })}<span
                                                                                    style={{ color: 'red' }}
                                                                                    className="ml-2">*</span></label>
                                                                            </div>
                                                                            <div
                                                                                className="col-sm-6 col-lg-6 form-group">
                                                                                <DatePicker
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                    className="form-control"
                                                                                    showYearDropdown={true}
                                                                                    minDate={new Date()}
                                                                                    placeholderText="Enter interview slot end date"
                                                                                    showMonthDropdown={true}
                                                                                    selected={this.state.Profile.slot_date_end ? Date.parse(this.state.Profile.slot_date_end) : ''}
                                                                                    onChange={(e) => this.handleChangeDate(e, 'slot_date_end')}
                                                                                />
                                                                                {validator.message('slot end date', this.state.Profile.slot_date_end, 'required')}
                                                                                {errorDateTo && <span style={{ "color": "red" }}>{errorDateTo}</span>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                <div className="row">

                                                                    <div className="col-sm-12 mb-0 col-lg-12 form-group text-center">
                                                                        <label className="form-check-label panelbtn" htmlFor="check1">
                                                                            <a className={"date-clr"}
                                                                                data-toggle="collapse" href="#my-panel3"
                                                                            >{this.props.t('JobPost.int_venue', { param: 'react' })}</a>

                                                                        </label>
                                                                    </div>
                                                                    <div id="my-panel3" className="collapse w-100">
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                        <label>{this.props.t('jobProviderProfile.add1', { param: 'react' })}<span
                                                                                            style={{ color: 'red' }}
                                                                                            className="ml-2">*</span></label>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-sm-4 col-lg-4 form-group">
                                                                                        <input type="text" className="form-control"
                                                                                            onChange={(e) => this.handleJobInput(e)}
                                                                                            value={this.state.Profile.address_Line1 ? this.state.Profile.address_Line1 : ''}
                                                                                            name="address_Line1"
                                                                                            placeholder="Enter Plot/S.C.O" />
                                                                                        {validator.message('address line 1', this.state.Profile.address_Line1, 'required')}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="row">
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                        <label>{this.props.t('jobProviderProfile.add2', { param: 'react' })}<span
                                                                                            style={{ color: 'red' }}
                                                                                            className="ml-2">*</span></label>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-sm-4 col-lg-4 form-group">
                                                                                        <input type="text" className="form-control"
                                                                                            onChange={(e) => this.handleJobInput(e)}
                                                                                            value={this.state.Profile.address_Line2 ? this.state.Profile.address_Line2 : ''}
                                                                                            name="address_Line2"
                                                                                            placeholder="Enter Plot/S.C.O" />
                                                                                        {validator.message('address line 2', this.state.Profile.address_Line2, 'required')}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div
                                                                                className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                        <label>{this.props.t('jobProviderProfile.add3', { param: 'react' })}<span
                                                                                            style={{ color: 'red' }}
                                                                                            className="ml-2">*</span></label>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-sm-4 col-lg-4 form-group">
                                                                                        <input type="text" className="form-control"
                                                                                            onChange={(e) => this.handleJobInput(e)}
                                                                                            value={this.state.Profile.address_Line3 ? this.state.Profile.address_Line3 : ''}
                                                                                            name="address_Line3"
                                                                                            placeholder="Enter Plot/S.C.O" />
                                                                                        {validator.message('address line 3', this.state.Profile.address_Line3, 'required')}
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                        </div>
                                                                        <div className="row">


                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                        <label>{this.props.t('JobPost.cont_person', { param: 'react' })}<span
                                                                                            style={{ color: 'red' }}
                                                                                            className="ml-1">*</span></label>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-sm-4 col-lg-4 form-group">
                                                                                        <input type="text"
                                                                                            onChange={this.handleJobInput}
                                                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('JobPost.cont_person', { param: 'react' })}

                                                                                            value={this.state.Profile.contact_person ? this.state.Profile.contact_person : ''}
                                                                                            className="form-control"
                                                                                            name="contact_person"
                                                                                        />
                                                                                        {validator.message('contact person', this.state.Profile.contact_person, 'required')}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                        <label>{this.props.t('JobPost.cont_deg', { param: 'react' })}<span
                                                                                            style={{ color: 'red' }}
                                                                                            className="ml-1">*</span></label>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-sm-4 col-lg-4 form-group">
                                                                                        <input type="text"
                                                                                            onChange={this.handleJobInput}
                                                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('JobPost.cont_deg', { param: 'react' })}
                                                                                            value={this.state.Profile.person_designation ? this.state.Profile.person_designation : ''}
                                                                                            className="form-control"
                                                                                            name="person_designation"
                                                                                            min="7" />
                                                                                        {validator.message('person designation', this.state.Profile.person_designation, 'required')}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className="row">

                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                        <label>{this.props.t('JobPost.cont_phone', { param: 'react' })}<span
                                                                                            style={{ color: 'red' }}
                                                                                            className="ml-1">*</span></label>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-sm-4 col-lg-4 form-group">
                                                                                        <input onChange={this.handleJobInput}
                                                                                            type="text"
                                                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('JobPost.cont_phone', { param: 'react' })}
                                                                                            value={this.state.Profile.contact_no ? this.state.Profile.contact_no : ''}
                                                                                            className="form-control"
                                                                                            name="contact_no" />
                                                                                        {validator.message('contact no', this.state.Profile.contact_no, 'required|phone')}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col-sm-4 col-lg-4 offset-md-2 text-left">
                                                                                        <label>{this.props.t('jobProviderProfile.city', { param: 'react' })}<span
                                                                                            style={{ color: 'red' }}
                                                                                            className="ml-1">*</span></label>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-sm-4 col-lg-4 form-group">
                                                                                        <input onChange={this.handleJobInput}
                                                                                            type="text"
                                                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('jobProviderProfile.city', { param: 'react' })}
                                                                                            value={this.state.Profile.city ? this.state.Profile.city : ''}
                                                                                            className="form-control"
                                                                                            name="city" />
                                                                                        {validator.message('city', this.state.Profile.city, 'required')}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <div className="row">

                                                                        <div className="col-sm-12 col-lg-12  mb-0 form-group text-center mt-4">
                                                                            <label className="form-check-label panelbtn" htmlFor="check1">
                                                                                <a className={"date-clr"}
                                                                                    data-toggle="collapse" href="#my-panel4"
                                                                                >{this.props.t('JobPost.int_date_time', { param: 'react' })}</a>

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="my-panel4" className="margin0 collapse w-100 p-3">
                                                                    <div className="row picker-custom">
                                                                        <div
                                                                            className="col-sm-4 col-lg-4 text-left">
                                                                            <label className="interview-head">{this.props.t('JobPost.int_date', { param: 'react' })}<span
                                                                                style={{ 'color': 'red' }}
                                                                                className="ml-2">*</span></label>
                                                                            <DayPicker
                                                                                selectedDays={this.state.selectedDays}
                                                                                onDayClick={this.handleDayClick}
                                                                                disabledDays={[new Date(), { before: new Date() }]}

                                                                            />
                                                                            {validator.message('interview dates', this.state.selectedDays, 'required')}
                                                                        </div>
                                                                        <div className="col-sm-5 col-lg-5  offset-md-3 col-md-5 picker-height">
                                                                            <div className="row">
                                                                                <div
                                                                                    className="col-sm-4 col-lg-4 offset-md-2 pull-left">
                                                                                    <label>{this.props.t('JobPost.timeSlot', { param: 'react' })}<span
                                                                                        style={{ color: 'red' }}
                                                                                        className="ml-2">*</span></label>
                                                                                </div>
                                                                                <div
                                                                                    className="col-sm-6 col-lg-6 form-group pull-right">
                                                                                    <select
                                                                                        value={this.state.Profile.time_slot ? this.state.Profile.time_slot : ''}
                                                                                        onChange={this.handleJobInput}
                                                                                        className="form-control"
                                                                                        name="time_slot">
                                                                                        <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('JobPost.timeSlot', { param: 'react' })}
                                                                                        </option>
                                                                                        {Mainprops.EmployerReducer.timeSlot && Mainprops.EmployerReducer.timeSlot.length > 0 &&
                                                                                            Mainprops.EmployerReducer.timeSlot.map(function (data, index) {
                                                                                                return (
                                                                                                    <option
                                                                                                        value={data.id}
                                                                                                        key={index}>{data.name}</option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                    {validator.message('time slot', this.state.Profile.time_slot, 'required')}
                                                                                </div>
                                                                            </div>
                                                                            {dates && dates.map((d, key) => {
                                                                                return (
                                                                                    <div key={key} >
                                                                                        <div className="w-100 text-center pt-3">
                                                                                            <span className="date-clr">
                                                                                                {this.props.t('JobPost.dateTime', { param: 'react' })}{moment(d.date).format('DD-MM-YYYY')}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                                                            <div className="row">
                                                                                                <div
                                                                                                    className="col-sm-6 col-lg-6">
                                                                                                    <label>{this.props.t('JobPost.int_start_time', { param: 'react' })}<span
                                                                                                        style={{ color: 'red' }}
                                                                                                        className="ml-2">*</span></label>
                                                                                                    <div
                                                                                                        className="form-group">
                                                                                                        <input type="time"
                                                                                                            value={d.start_time ? d.start_time : ''}
                                                                                                            onChange={(e) => this.handleChangeTimeInput(e, key)}
                                                                                                            className="form-control"
                                                                                                            name="start_time"
                                                                                                            placeholder="Enter interview time" />
                                                                                                        <span className="date-clr"
                                                                                                            style={{ "fontSize": "12px" }}>
                                                                                                            {this.props.t('JobPost.timeInst', { param: 'react' })}
                                                                                                        </span>
                                                                                                        {d.error_start_time && <span className="d-block" style={{ "color": "red" }}>
                                                                                                            {d.error_start_time}
                                                                                                        </span>}

                                                                                                    </div>
                                                                                                </div>

                                                                                                <div
                                                                                                    className="col-sm-6 col-lg-6">
                                                                                                    <label>{this.props.t('JobPost.int_end_time', { param: 'react' })}<span
                                                                                                        style={{ color: 'red' }}
                                                                                                        className="ml-2">*</span></label>
                                                                                                    <div
                                                                                                        className="form-group">
                                                                                                        <input type="time"
                                                                                                            value={d.end_time ? d.end_time : ''}
                                                                                                            onChange={(e) => this.handleChangeTimeInput(e, key)}
                                                                                                            className="form-control"
                                                                                                            name="end_time"
                                                                                                            placeholder="Enter interview time" />
                                                                                                        <span className="date-clr"
                                                                                                            style={{ "fontSize": "12px" }}>
                                                                                                            {this.props.t('JobPost.timeInst', { param: 'react' })}
                                                                                                        </span>
                                                                                                        {d.error_end_time && <span className="d-block" style={{ "color": "red" }}>
                                                                                                            {d.error_end_time}
                                                                                                        </span>}
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>

                                                                                    </div>

                                                                                )
                                                                            })}

                                                                        </div>
                                                                    </div>


                                                                </div>
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className=" col-sm-12 col-lg-12">
                                                                            <hr />
                                                                            <button type="submit"
                                                                                className="btn font20 btn-primary mx-auto d-block btn-primary2 mt-1">{this.props.t('JobPost.submit', { param: 'react' })}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </section>
                                                        </form>
                                                    </div>  

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Validation(PostJob)));
