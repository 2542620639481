import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import {
    get_education, get_User_District, getDbeesAddress
} from '../../../actions/accountAction';
import { getTimeSlots, getJobProviderAddress } from '../../../actions/employerAction';
import {
    postCounselling, getCounsellingType,
    getCounsellingModes, setPostCounsellingStatus,
    getCounsellingById, updateCounselling, setSessionData
} from '../../../actions/counsellingproviderAction'
import store from '../../../store';
import Sidebar from './Sidebar';
import Validation from "../../hoc/Validation";
import moment from 'moment'
import DatePicker from "react-datepicker";
import { Multiselect } from 'multiselect-react-dropdown';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Link } from 'react-router'

class PostCounselling extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Profile: [],
            errorDateFrom: '',
            errorDateTo: '',
            setData: false,
            selectedDays: [],
            dates: [],
            errorTime: false
        }
        store.dispatch(get_User_District());
        store.dispatch(getDbeesAddress());
        store.dispatch(getCounsellingType());
        store.dispatch(getCounsellingModes());
        store.dispatch(get_education());
        store.dispatch(getTimeSlots());
        store.dispatch(getJobProviderAddress());
        if (this.props.location && this.props.location.query &&
            this.props.location.query.edit && this.props.location.query.edit !== "") {
            store.dispatch(getCounsellingById(this.props.location.query.edit))
        }
    }

    getDbeeAddress = (id) => {
        if (this.props.actionReducer.addressData && this.props.actionReducer.addressData.length && id) {
            const filterAddress = this.props.actionReducer.addressData.filter((d) => d.id === id)
            return filterAddress && filterAddress.length ? filterAddress[0].address : ''
        }
        return ''
    }

    componentDidMount() {
        document.title = this.props.t('counsellingProvider.postCounselling', { param: 'react' });
        const Profile = this.state.Profile
        Profile['coun_held'] = "0"
        this.setState({ Profile })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.counsellingproviderReducer.postCounsellingStatus) {
            this.props.router.push('myaccount')
        }
        const Profile = nextProps.counsellingproviderReducer.sessionData
        if (Profile &&
            Object.keys(Profile).length &&
            !this.state.setData) {
            if (Profile.mode === '2' && Profile.modeType === '1') {
                let dates = Profile.date
                let start_time = Profile.start_time
                let end_time = Profile.end_time
                dates = JSON.parse(dates)
                start_time = JSON.parse(start_time)
                end_time = JSON.parse(end_time)
                const selectedDays = []
                const dateList = []
                if (dates.length) {

                    dates.forEach((d, index) => {
                        let dayList = d.split('-')
                        let day = `${dayList[0]}/${dayList[1]}/${dayList[2]}`
                        selectedDays.push(new Date(day))
                        dateList.push({
                            date: `${dayList[0]}-${dayList[1]}-${dayList[2]}`,
                            start_time: start_time[index],
                            end_time: end_time[index],
                            error_start_time: "",
                            error_end_time: ""
                        })

                    })

                    this.setState({ selectedDays, dates: dateList })
                }
            }

            this.setState({ Profile, setData: true })
        }
    }

    componentWillUnmount() {
        store.dispatch(setPostCounsellingStatus(false))
        if (this.props.counsellingproviderReducer.sessionData && Object.keys(this.props.counsellingproviderReducer.sessionData).length) {
            store.dispatch(setSessionData([]))
        }
    }

    handleChangeDate = (date, key) => {
        const Profile = this.state.Profile
        let formatedDate = moment(date).format('YYYY-MM-DD').split("-")

        let errorDateTo = ''
        let errorDateFrom = ''
        if (key === 'valid_from' && Profile.expire_on) {
            let newFormatedDate = `${formatedDate[0].substring(0, 4)}-${formatedDate[1]}-${formatedDate[2]}`
            if (moment(newFormatedDate).isSameOrAfter(Profile.expire_on)) {
                errorDateFrom = 'Session Start Date must not be greater than equal to the Session End Date'
            } else {
                errorDateFrom = ''
                this.setDatePickerValues(formatedDate, key)
            }
            this.setState({ errorDateFrom })

        } else if (key === 'expire_on' && Profile.valid_from) {
            let newFormatedDate = `${formatedDate[0].substring(0, 4)}-${formatedDate[1]}-${formatedDate[2]}`

            if (moment(newFormatedDate).isSameOrBefore(Profile.valid_from)) {
                errorDateTo = 'Session End Date must not be less than or equal to the Session Start Date'
            } else {
                this.setDatePickerValues(formatedDate, key)
                errorDateTo = ''
            }
            this.setState({ errorDateTo })

        } else {
            this.setDatePickerValues(formatedDate, key)
        }

    }

    handleChangeTimeInput = (e, key) => {
        const name = e.target.name
        const value = e.target.value
        let newValue = value.replace(':', '.')
        let dates = this.state.dates;
        let lunchstart = 13.30;
        let minutes = 0;
        if (this.state.Profile.time_slot === "1") {
            lunchstart = 13.15;
            minutes = 15;
        }
        else if (this.state.Profile.time_slot === "2") {
            lunchstart = 13.00;
            minutes = 30;
        }
        else if (this.state.Profile.time_slot === "3") {
            lunchstart = 12.45;
            minutes = 45;
        }
        else if (this.state.Profile.time_slot === "4") {
            lunchstart = 12.30;
            minutes = 60;
        }
        if ((value === '13:30' || (parseFloat(newValue) > lunchstart && parseFloat(newValue) <= 14.30)) &&
            (name === 'start_time' || name === 'end_time')) {
            dates[key]['start_time'] = ""
            dates[key]['end_time'] = ""
        } else {
            dates[key][name] = value
        }


        if (!value && name === 'start_time') {
            dates[key]['error_start_time'] = `Session start time is required`

        }
        if ((value === '13:30' || (parseFloat(newValue) > lunchstart && parseFloat(newValue) <= 14.30)) && (name === 'start_time' || name === 'end_time')) {
            dates[key]['error_start_time'] = `Cannot select time between 1:30 PM to 2:30 PM`
        }
        else if (dates[key]['error_start_time'] !== "" && name === 'start_time') {
            dates[key]['error_start_time'] = ""
            this.setState({ errorTime: false })
        }
        let starttime = dates[key]['start_time'].match(/(\d+)(?::(\d\d))?\s*(p?)/);
        let endtime = dates[key]['end_time'].match(/(\d+)(?::(\d\d))?\s*(p?)/);
        if (value && starttime && endtime) {
            let startdate = new Date();
            startdate.setHours(parseInt(starttime[1]) + (starttime[3] ? 12 : 0));
            startdate.setMinutes(parseInt(starttime[2]) || 0);
            startdate.setMinutes(startdate.getMinutes() + minutes);
            let enddate = new Date();
            enddate.setHours(parseInt(endtime[1]) + (endtime[3] ? 12 : 0));
            enddate.setMinutes(parseInt(endtime[2]) || 0);
            if (startdate > enddate) {
                dates[key]['error_start_time'] = 'Cannot select time before ' + startdate.getHours() + ':' + startdate.getMinutes();
            }
            else {
                dates[key]['error_start_time'] = ""
                this.setState({ errorTime: false })
            }
        }
        if (!value && name === 'end_time') {
            dates[key]['error_end_time'] = `Session end time is required`
        } else if (dates[key]['error_end_time'] !== "" && name === 'end_time') {
            dates[key]['error_end_time'] = ""
            this.setState({ errorTime: false })
        }
        this.setState({ dates })
    }

    handleDayClick = (day, { selected }) => {
        const { selectedDays } = this.state;

        const after = moment(day).format('YYYY-MM-DD')
        const before = moment().format('YYYY-MM-DD')

        const oldDates = this.state.dates
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
            oldDates.splice(selectedIndex, 1);
            this.setState({ dates: oldDates })
        } else if (moment(after).isAfter(before)) {
            selectedDays.push(day);
            const dates = []
            dates.push({
                date: moment(day).format('YYYY-MM-DD'),
                start_time: "",
                end_time: "",
                error_start_time: "",
                error_end_time: ""
            })
            this.setState({ dates: [...oldDates, ...dates] })
        }
        this.setState({ selectedDays });
    }

    setDatePickerValues = (formatedDate, key) => {
        const Profile = this.state.Profile
        if (formatedDate[0].length === 4) {
            formatedDate = `${formatedDate[0].substring(0, 4)}-${formatedDate[1]}-${formatedDate[2]}`
            Profile[key] = formatedDate
            this.setState({ Profile });
        }
    }

    onSelectChange = (selectedList) => {
        const { Profile } = this.state
        const district = []
        let filterDist1 = selectedList.filter((dist) => dist.id === '14')
        if (filterDist1 && filterDist1.length) {
            let distData = this.props.actionReducer.Educations
            distData = [{ id: "14", name: 'All' }, ...distData]
            selectedList = distData
        }
        selectedList.forEach((dist) => {
            district.push(dist.id)
        })

        Profile['education_level'] = district
        this.setState({ Profile })
    }

    getDatePicker = (key) => {
        const { Profile } = this.state
        const placeholder = key === 'expire_on' ?
            this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.end_date', { param: 'react' }) :
            this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.valid_from', { param: 'react' })
        return (
            <DatePicker
                dateFormat="dd/MM/yyyy"
                className="form-control w-100"
                minDate={new Date()}
                placeholderText={placeholder}
                showYearDropdown={true}
                showMonthDropdown={true}
                selected={Profile[`${key}`] ? Date.parse(Profile[`${key}`]) : ''}
                onChange={(e) => this.handleChangeDate(e, key)}
            />
        )
    }

    checkModeFields = () => {
        const dates = this.state.dates
        const filter_date_start_time = dates.findIndex((d) => d.start_time === "")
        if (filter_date_start_time !== -1) {
            dates[filter_date_start_time]['error_start_time'] = "Session start time is required"
            this.setState({ errorTime: true })
        }
        const filter_date_end_time = dates.findIndex((d) => d.end_time === "")
        if (filter_date_end_time !== -1) {
            dates[filter_date_end_time]['error_end_time'] = "Session end time is required"
            this.setState({ errorTime: true })
        }
    }

    onChange = (evt) => {
        let newContent = evt.target.value;
        let Profile = this.state.Profile;
        Profile['description'] = newContent;
        this.setState({ Profile });
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        await this.checkModeFields()
        const { dates } = this.state
        const Profile = this.state.Profile
        if (this.props.validator.allValid() && !this.state.errorTime) {
            if (dates && dates.length) {
                Profile['session_dates'] = dates
            }

            if (Profile.mode === '2' && Profile.modeType === '1') {
                Profile['valid_from'] = dates[0].date
                Profile['expire_on'] = dates[dates.length - 1].date
            }
            this.setState({ Profile })
            if (this.props.counsellingproviderReducer.sessionData &&
                Object.keys(this.props.counsellingproviderReducer.sessionData).length) {
                await store.dispatch(updateCounselling(this.state.Profile))
            } else {
                await store.dispatch(postCounselling(this.state.Profile))
            }

            await this.props.validator.hideMessages()
            await this.setState({ Profile: [] });
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }
    }

    handleSessionInput = (e) => {
        e.persist();
        const name = e.target.name;
        let value = e.target.value;
        let type = e.target.type;
        if (type === 'text') {
            let regex = /^[A-Za-z0-9 ]+$/
            if (!regex.test(value) && value) {
                return false
            }
        }
        let Profile = this.state.Profile;
        if (name === 'dbee' && value) {
            Profile['address'] = this.getDbeeAddress(value)
        } else if (name === 'dbee' && value) {
            delete Profile['address']
        }

        if (name === 'coun_held' || name === 'modeType') {
            this.props.validator.purgeFields()
        }
        if (e.target.type === 'number' && parseInt(value) <= 0) {
            value = ''
            e.target.value = ''
        }
        if (name === 'mode' && value !== "2") {
            delete Profile['address']
            delete Profile['phone_no']
            delete Profile['coun_link']
            delete Profile['start_time']
            delete Profile['end_time']
            delete Profile['time_slot']
            delete Profile['session_date']
            delete Profile['coun_held']
            delete Profile['username']
            delete Profile['password']
            delete Profile['dbee']
            delete Profile['modeType']

        }
        if (name === 'mode') {
            this.props.validator.purgeFields()
            this.setState({ dates: [], selectedDays: [] })
        }
        if (name === 'modeType' && value === "1") {
            delete Profile['timing_from']
            delete Profile['timing_to']
            delete Profile['expire_on']
            delete Profile['valid_from']
        } else if (name === 'modeType' && value === "2") {
            delete Profile['address']
            Profile['coun_held'] = "0"
            delete Profile['dbee']
            delete Profile['time_slot']
            this.setState({ dates: [], selectedDays: [] })
            if (this.props.location.query.edit) {
                delete Profile['valid_from']
                delete Profile['expire_on']
            }
        }

        Profile[name] = value;
        this.setState({ Profile });
    }
    
    render() {
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        const editable = this.props.location.query.edit
        let Mainprops = this.props
        const { Profile, errorDateFrom, errorDateTo, dates } = this.state

        const { validator } = this.props
        const selectedDist = []
        let distData = Mainprops.actionReducer.Educations
        distData = [{ id: "14", name: 'All' }, ...distData]
        if (this.state.Profile.education_level && this.state.Profile.education_level.length) {
            this.state.Profile.education_level.forEach((dist) => {
                let fileter = distData.filter((fil) => fil.id === dist)
                if (fileter && fileter.length) {
                    selectedDist.push(fileter[0])
                }
            })
        }

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col-md-10 second-side py-2 pl-4 h-100 m-0 mb-3">
                            <div className="clearfix"></div>
                            <div className="tab-content">
                                <div id="home" className=" tab-pane active">
                                    <section className="login-form bg-education mt-2 p-0">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-12  mx-auto   p-0">
                                                    <div className="panel-heading">
                                                        <h6 className="mb-0">{this.props.t('counsellingProvider.postCounselling', { param: 'react' })}</h6>
                                                    </div>

                                                    <div className="panel-body m-0 p-2">
                                                        {editable && Profile.hasOwnProperty('applied') && Profile.applied ? <div className="panel-body m-0 p-0">
                                                            <h6>{this.props.t('counsellingProvider.sesssionEdit', { param: 'react' })}.
                                                        <Link className="ml-1 date-clr" style={{ cursor: "pointer" }} to="/session-calendar">{this.props.t('jobSearch.goBack', { param: 'react' })}</Link>
                                                            </h6>

                                                        </div> : null}
                                                        {!editable || editable && (Profile.hasOwnProperty('applied') && !Profile.applied) ?
                                                            <div className="panel-body m-0 p-0">
                                                                <form onSubmit={this.handleSubmit}>
                                                                    <div className="bg-education p-3">
                                                                        <div className={"row"}>

                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col-sm-6 col-lg-6 text-right">
                                                                                        <label>{this.props.t('counsellingProvider.mode', { param: 'react' })}</label><span
                                                                                            style={{ color: 'red' }}
                                                                                            className="ml-2">*</span>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-sm-6 col-lg-6 form-group">

                                                                                        <select name="mode"
                                                                                            className="form-control"
                                                                                            onChange={this.handleSessionInput}
                                                                                            value={Profile.mode ? Profile.mode : ''}>
                                                                                            <option value="">{this.props.t('placeholder.select', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.mode', { param: 'react' })}
                                                                                            </option>
                                                                                            {this.props.counsellingproviderReducer && this.props.counsellingproviderReducer.modes &&
                                                                                                this.props.counsellingproviderReducer.modes.length ?
                                                                                                this.props.counsellingproviderReducer.modes.map((result, key) => {
                                                                                                    return (
                                                                                                        <option key={key} value={result.id}>{result.name}
                                                                                                        </option>
                                                                                                    )
                                                                                                }) : ''}
                                                                                        </select>
                                                                                        {validator.message('Session Mode', Profile.mode, 'required')}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {Profile.mode && Profile.mode === '2' ?
                                                                                <div
                                                                                    className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                                                                    <div className="row">
                                                                                        <div
                                                                                            className="col-sm-6 col-lg-6 text-right">
                                                                                            <label>{this.props.t('counsellingProvider.modeType', { param: 'react' })}</label><span
                                                                                                style={{ color: 'red' }}
                                                                                                className="ml-2">*</span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-sm-6 col-lg-6 form-group">
                                                                                            <select value={Profile.modeType ? Profile.modeType : ''}
                                                                                                onChange={this.handleSessionInput}
                                                                                                className="form-control"
                                                                                                name="modeType">
                                                                                                <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('counsellingProvider.modeType', { param: 'react' })}
                                                                                                </option>
                                                                                                <option value="1">Individual</option>
                                                                                                <option value="2">Group</option>
                                                                                            </select>
                                                                                            {validator.message('mode type', Profile.modeType, 'required')}
                                                                                        </div>
                                                                                    </div>
                                                                                </div> : null}

                                                                            {Profile.mode && Profile.mode === '1' &&
                                                                                <div
                                                                                    className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                                                                    <div className="row">
                                                                                        <div
                                                                                            className="col-sm-6 col-lg-6 text-right">
                                                                                            <label>{this.props.t('JobPost.cont_phone', { param: 'react' })}</label><span
                                                                                                style={{ color: 'red' }}
                                                                                                className="ml-2">*</span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-sm-6 col-lg-6 form-group">
                                                                                            <input
                                                                                                onChange={this.handleSessionInput}
                                                                                                type="number"
                                                                                                value={Profile.phone_no ? Profile.phone_no : ''}
                                                                                                className="form-control"
                                                                                                name="phone_no"
                                                                                                placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('JobPost.cont_phone', { param: 'react' })} />
                                                                                            {validator.message('Phone Number', Profile.phone_no, 'phone|required')}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>}
                                                                            {Profile.mode && Profile.mode === '3' &&
                                                                                <div
                                                                                    className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                                                                    <div className="row">
                                                                                        <div
                                                                                            className="col-sm-6 col-lg-6 text-right">
                                                                                            <label>{this.props.t('counsellingProvider.link', { param: 'react' })}</label><span
                                                                                                style={{ color: 'red' }}
                                                                                                className="ml-2">*</span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-sm-6 col-lg-6 form-group">
                                                                                            <input onChange={this.handleSessionInput}
                                                                                                type="url"
                                                                                                value={Profile.coun_link ? Profile.coun_link : ''}
                                                                                                className="form-control"
                                                                                                name="coun_link"
                                                                                                placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.link', { param: 'react' })} />
                                                                                            {validator.message('link', Profile.coun_link, 'required')}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>}
                                                                            {Profile.mode && Profile.mode === '2' && Profile.modeType === '1' &&
                                                                                <>

                                                                                    <div className="col-sm-12 col-md-12 col-lg-6 ">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-6 col-lg-6 text-right">
                                                                                                <label>{this.props.t('JobPost.coun_held', { param: 'react' })}<span
                                                                                                    style={{ color: 'red' }}
                                                                                                    className="ml-2">*</span></label>
                                                                                            </div>
                                                                                            <div className="col-sm-6 col-lg-6 form-group">

                                                                                                <div
                                                                                                    className="form-check-inline">
                                                                                                    <label
                                                                                                        className="form-check-label text-center mr-2"
                                                                                                        htmlFor="radio1">
                                                                                                        <input type="radio"
                                                                                                            onChange={this.handleSessionInput}
                                                                                                            className="form-check-input"
                                                                                                            name="coun_held"
                                                                                                            value={'1'}
                                                                                                            checked={Profile.coun_held === '1'} />{this.props.t('profileDetails.yes', { param: 'react' })}
                                                                                                    </label>
                                                                                                    <label
                                                                                                        className="form-check-label text-center"
                                                                                                        htmlFor="radio1">
                                                                                                        <input type="radio"
                                                                                                            onChange={this.handleSessionInput}
                                                                                                            className="form-check-input"
                                                                                                            name="coun_held"
                                                                                                            value={'0'}
                                                                                                            checked={Profile.coun_held === '0'} />{this.props.t('profileDetails.no', { param: 'react' })}
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>


                                                                                    </div>
                                                                                    {Profile.coun_held === '1' ?
                                                                                        <div
                                                                                            className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                                                                            <div className="row">
                                                                                                <div
                                                                                                    className="col-sm-6 col-lg-6 text-right">
                                                                                                    <label>{this.props.t('counsellingProvider.dbee', { param: 'react' })}</label><span
                                                                                                        style={{ color: 'red' }}
                                                                                                        className="ml-2">*</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-sm-6 col-lg-6 form-group">
                                                                                                    <select value={Profile.dbee ? Profile.dbee : ''}
                                                                                                        className="form-control"
                                                                                                        onChange={this.handleSessionInput}
                                                                                                        name="dbee">
                                                                                                        <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('counsellingProvider.dbee', { param: 'react' })}
                                                                                                        </option>
                                                                                                        {this.props.actionReducer.addressData && this.props.actionReducer.addressData.length ? this.props.actionReducer.addressData.map((add, key) => {
                                                                                                            return (<option key={key} value={add.id}>{add.name}</option>)
                                                                                                        }) : null}
                                                                                                    </select>
                                                                                                    {validator.message('dbee', Profile.dbee, 'required')}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> : null}

                                                                                    <div
                                                                                        className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                                                                        <div className="row">
                                                                                            <div
                                                                                                className="col-sm-6 col-lg-6 text-right">
                                                                                                <label>{this.props.t('profileDetails.communication',
                                                                                                    { param: 'react' })}</label><span
                                                                                                        style={{ color: 'red' }}
                                                                                                        className="ml-2">*</span>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-sm-6 col-lg-6 form-group">
                                                                                                <input onChange={this.handleSessionInput}
                                                                                                    type="text"
                                                                                                    value={Profile.address ? Profile.address : ''}
                                                                                                    className="form-control"
                                                                                                    name="address"
                                                                                                    placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('profileDetails.communication', { param: 'react' })} />
                                                                                                {validator.message('address', Profile.address, 'required')}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-12 col-lg-6 ">
                                                                                        <div className="row">
                                                                                            <div
                                                                                                className="col-sm-6 col-lg-6 text-right">
                                                                                                <label>{this.props.t('JobPost.timeSlot', { param: 'react' })}<span
                                                                                                    style={{ color: 'red' }}
                                                                                                    className="ml-2">*</span></label>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-sm-6 col-lg-6 form-group">
                                                                                                <select
                                                                                                    value={this.state.Profile.time_slot ? this.state.Profile.time_slot : ''}
                                                                                                    onChange={this.handleSessionInput}
                                                                                                    className="form-control"
                                                                                                    name="time_slot">
                                                                                                    <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('JobPost.timeSlot', { param: 'react' })}
                                                                                                    </option>
                                                                                                    {Mainprops.EmployerReducer.timeSlot && Mainprops.EmployerReducer.timeSlot.length > 0 &&
                                                                                                        Mainprops.EmployerReducer.timeSlot.map(function (data, index) {
                                                                                                            return (
                                                                                                                <option
                                                                                                                    value={data.id}
                                                                                                                    key={index}>{data.name}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </select>
                                                                                                {validator.message('time_slot', Profile.time_slot, 'required')}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>

                                                                            }

                                                                        </div>
                                                                        {Profile.mode && Profile.mode === '3' &&
                                                                            <div className="row">

                                                                                <div
                                                                                    className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                                                                    <div className="row">
                                                                                        <div
                                                                                            className="col-sm-6 col-lg-6 text-right">
                                                                                            <label>{this.props.t('counsellingProvider.username', { param: 'react' })}<span
                                                                                                style={{ color: 'red' }}
                                                                                                className="ml-2">*</span></label>
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-sm-6 col-lg-6 form-group">
                                                                                            <input onChange={this.handleSessionInput}
                                                                                                type="text"
                                                                                                value={Profile.username ? Profile.username : ''}
                                                                                                className="form-control"
                                                                                                name="username"
                                                                                                placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.username', { param: 'react' })} />
                                                                                            {validator.message('username', Profile.username, 'required')}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                                                                    <div className="row">
                                                                                        <div
                                                                                            className="col-sm-6 col-lg-6 text-right">
                                                                                            <label>{this.props.t('counsellingProvider.password', { param: 'react' })}<span
                                                                                                style={{ color: 'red' }}
                                                                                                className="ml-2">*</span></label>
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-sm-6 col-lg-6 form-group">
                                                                                            <input onChange={this.handleSessionInput}
                                                                                                type="password"
                                                                                                value={Profile.password ? Profile.password : ''}
                                                                                                className="form-control"
                                                                                                name="password"
                                                                                                placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.password', { param: 'react' })} />
                                                                                            {validator.message('password', Profile.password, 'required')}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }


                                                                        {Profile.mode === '2' && Profile.modeType === '1' ?
                                                                            <div className="row picker-custom">

                                                                                <div
                                                                                    className="col-sm-4 col-lg-4 text-left">
                                                                                    <h5 className="pl-4">{this.props.t('counsellingProvider.counsellingDate', { param: 'react' })}<span
                                                                                        style={{ color: 'red' }}
                                                                                        className="ml-2">*</span></h5>
                                                                                    <DayPicker
                                                                                        selectedDays={this.state.selectedDays}
                                                                                        onDayClick={this.handleDayClick}
                                                                                        disabledDays={[new Date(), { before: new Date() }]}
                                                                                    />
                                                                                    {validator.message('session dates', this.state.selectedDays, 'required')}
                                                                                </div>
                                                                                <div className="col-sm-5 col-lg-5  offset-md-3 col-md-5 picker-height">
                                                                                    {dates && dates.map((d, key) => {
                                                                                        return (
                                                                                            <div key={key} >
                                                                                                <div className="w-100 text-center pt-3">
                                                                                                    <span className="date-clr">
                                                                                                        {this.props.t('counsellingProvider.session_time', { param: 'react' })}{moment(d.date).format('DD-MM-YYYY')}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                                                                    <div className="row">
                                                                                                        <div
                                                                                                            className="col-sm-6 col-lg-6">
                                                                                                            <label>{this.props.t('counsellingProvider.session_start_time', { param: 'react' })}<span
                                                                                                                style={{ color: 'red' }}
                                                                                                                className="ml-2">*</span></label>
                                                                                                            <div
                                                                                                                className="form-group">
                                                                                                                <input type="time"
                                                                                                                    value={d.start_time ? d.start_time : ''}
                                                                                                                    onChange={(e) => this.handleChangeTimeInput(e, key)}
                                                                                                                    className="form-control"
                                                                                                                    name="start_time"
                                                                                                                />
                                                                                                                <span className="date-clr"
                                                                                                                    style={{ "fontSize": "12px" }}>
                                                                                                                    {this.props.t('JobPost.timeInst', { param: 'react' })}
                                                                                                                </span>
                                                                                                                {d.error_start_time && <span className="d-block" style={{ "color": "red" }}>
                                                                                                                    {d.error_start_time}
                                                                                                                </span>}

                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div
                                                                                                            className="col-sm-6 col-lg-6">
                                                                                                            <label>{this.props.t('counsellingProvider.session_end_time', { param: 'react' })}<span
                                                                                                                style={{ color: 'red' }}
                                                                                                                className="ml-2">*</span></label>
                                                                                                            <div
                                                                                                                className="form-group">
                                                                                                                <input type="time"
                                                                                                                    value={d.end_time ? d.end_time : ''}
                                                                                                                    onChange={(e) => this.handleChangeTimeInput(e, key)}
                                                                                                                    className="form-control"
                                                                                                                    name="end_time"
                                                                                                                />
                                                                                                                <span className="date-clr"
                                                                                                                    style={{ "fontSize": "12px" }}>
                                                                                                                    {this.props.t('JobPost.timeInst', { param: 'react' })}
                                                                                                                </span>
                                                                                                                {d.error_end_time && <span className="d-block" style={{ "color": "red" }}>
                                                                                                                    {d.error_end_time}
                                                                                                                </span>}
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>

                                                                                        )
                                                                                    })}

                                                                                </div>
                                                                            </div> : null}
                                                                        {Profile.modeType !== '1' && Profile.mode ?
                                                                            <>
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                                                                        <div className="row">
                                                                                            <div
                                                                                                className="col-sm-6 col-lg-6 text-right">
                                                                                                <label>{this.props.t('counsellingProvider.valid_from', { param: 'react' })}<span
                                                                                                    style={{ color: 'red' }}
                                                                                                    className="ml-1">*</span></label>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-sm-6 col-lg-6 form-group">
                                                                                                {this.getDatePicker('valid_from')}

                                                                                                {validator.message('Session Start Date', Profile.valid_from, 'required')}
                                                                                                {errorDateFrom && <span style={{ "color": "red" }}>{errorDateFrom}</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                                                                        <div className="row">
                                                                                            <div
                                                                                                className="col-sm-6 col-lg-6 text-right">
                                                                                                <label>{this.props.t('counsellingProvider.end_date', { param: 'react' })}<span
                                                                                                    style={{ color: 'red' }}
                                                                                                    className="ml-1">*</span></label>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-sm-6 col-lg-6 form-group">
                                                                                                {this.getDatePicker('expire_on')}
                                                                                                {validator.message('Session End Date', Profile.expire_on, 'required')}
                                                                                                {errorDateTo && <span style={{ "color": "red" }}>{errorDateTo}</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="col-sm-12 col-md-12 col-lg-6">
                                                                                        <div className="row">
                                                                                            <div
                                                                                                className="col-sm-6 col-lg-6 text-right">
                                                                                                <label>{this.props.t('counsellingProvider.timing_from', { param: 'react' })}<span
                                                                                                    style={{ color: 'red' }}
                                                                                                    className="ml-1">*</span></label>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-sm-6 col-lg-6 form-group">
                                                                                                <input onChange={this.handleSessionInput}
                                                                                                    type="time"
                                                                                                    placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.timing_from', { param: 'react' })}
                                                                                                    value={Profile.timing_from ? Profile.timing_from : ''}
                                                                                                    className="form-control"
                                                                                                    name="timing_from" />
                                                                                                <span className="date-clr" style={{ "fontSize": "12px" }}>
                                                                                                    {this.props.t('JobPost.timeInst', { param: 'react' })}
                                                                                                </span>
                                                                                                {validator.message('timing from', Profile.timing_from, 'required')}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                                                                        <div className="row">
                                                                                            <div
                                                                                                className="col-sm-6 col-lg-6 text-right">
                                                                                                <label>{this.props.t('counsellingProvider.timing_to', { param: 'react' })}<span
                                                                                                    style={{ color: 'red' }}
                                                                                                    className="ml-1">*</span></label>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-sm-6 col-lg-6 form-group">
                                                                                                <input onChange={this.handleSessionInput}
                                                                                                    type="time"
                                                                                                    placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.timing_to', { param: 'react' })}
                                                                                                    value={Profile.timing_to ? Profile.timing_to : ''}
                                                                                                    className="form-control"
                                                                                                    name="timing_to" />
                                                                                                <span className="date-clr" style={{ "fontSize": "12px" }}>
                                                                                                    {this.props.t('JobPost.timeInst', { param: 'react' })}
                                                                                                </span>
                                                                                                {validator.message('timing to', Profile.timing_to, 'required')}

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                </div></> : null}
                                                                        <div className="row">
                                                                            <div className="col-sm-12 col-md-12 col-lg-6 ">
                                                                                <div className="row">
                                                                                    <div className="col-sm-6 text-right">
                                                                                        <label>{this.props.t('counsellingProvider.title', { param: 'react' })}</label><span
                                                                                            style={{ color: 'red' }}
                                                                                            className="ml-2">*</span>
                                                                                    </div>
                                                                                    <div className="col-sm-6 form-group">
                                                                                        <input onChange={this.handleSessionInput}
                                                                                            type="text"
                                                                                            value={Profile.title ? Profile.title : ''}
                                                                                            className="form-control"
                                                                                            name="title"
                                                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.title', { param: 'react' })} />
                                                                                        {validator.message('Title Of The Session', Profile.title, 'required')}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col-sm-6 col-lg-6 text-right">
                                                                                        <label>{this.props.t('counsellingProvider.eduLevel', { param: 'react' })}<span
                                                                                            style={{ color: 'red' }}
                                                                                            className="ml-1">*</span></label>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-sm-6 col-lg-6 form-group" id="postSession">

                                                                                        <Multiselect
                                                                                            closeOnSelect={false}
                                                                                            placeholder={this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('counsellingProvider.eduLevel', { param: 'react' })}
                                                                                            showCheckbox={true}
                                                                                            options={distData} // Options to display in the dropdown
                                                                                            selectedValues={selectedDist && selectedDist.length ? selectedDist : []} // Preselected value to persist in dropdown
                                                                                            onSelect={this.onSelectChange} // Function will trigger on select event
                                                                                            onRemove={this.onSelectChange} // Function will trigger on remove event
                                                                                            displayValue="name" // Property name to display in the dropdown options
                                                                                        />
                                                                                        {Profile.education_level && Profile.education_level.length ?
                                                                                            <span className="date-clr" style={{ cursor: "pointer" }} onClick={() => {
                                                                                                const { Profile } = this.state
                                                                                                Profile['education_level'] = []
                                                                                                this.setState({ Profile })
                                                                                            }}>{this.props.t('counsellingProvider.clearAll', { param: 'react' })}</span> : null}
                                                                                        {validator.message('Participant Educational Profile', Profile.education_level, 'required')}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={"row"}>
                                                                            <div className="col-sm-12 col-md-12 col-lg-6 ">
                                                                                <div className="row">
                                                                                    <div className="col-sm-6 text-right">
                                                                                        <label>{this.props.t('counsellingProvider.type', { param: 'react' })}</label><span
                                                                                            style={{ color: 'red' }}
                                                                                            className="ml-2">*</span>
                                                                                    </div>
                                                                                    <div className="col-sm-6 form-group">
                                                                                        <select name="type"
                                                                                            className="form-control"
                                                                                            onChange={this.handleSessionInput}
                                                                                            value={Profile.type ? Profile.type : ''}>
                                                                                            <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('counsellingProvider.type', { param: 'react' })}
                                                                                            </option>
                                                                                            {this.props.counsellingproviderReducer && this.props.counsellingproviderReducer.counsellingType &&
                                                                                                this.props.counsellingproviderReducer.counsellingType.length ?
                                                                                                this.props.counsellingproviderReducer.counsellingType.map((result, key) => {
                                                                                                    return (
                                                                                                        <option key={key} value={result.id}>{result.name}
                                                                                                        </option>
                                                                                                    )
                                                                                                }) : ''}
                                                                                        </select>
                                                                                        {validator.message('Type Of The Session', Profile.type, 'required')}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col-sm-6 col-lg-6 text-right">
                                                                                        <label>{this.props.t('counsellingProvider.seat', { param: 'react' })}<span
                                                                                            style={{ color: 'red' }}
                                                                                            className="ml-1">*</span></label>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-sm-6 col-lg-6 form-group">
                                                                                        <input onChange={this.handleSessionInput}
                                                                                            type="number"
                                                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.seat', { param: 'react' })}
                                                                                            value={Profile.seat ? Profile.seat : ''}
                                                                                            className="form-control"
                                                                                            name="seat" />
                                                                                        {validator.message('Maximum Participants', Profile.seat, 'required|numeric')}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-sm-12 col-md-12 col-lg-12 mt-3 mb-4">
                                                                                <label>{this.props.t('counsellingProvider.description', { param: 'react' })}</label><span
                                                                                    style={{ color: 'red' }} className="ml-2">*</span>
                                                                                <hr className={"mt-1"} />

                                                                                <textarea
                                                                                    className="w-100"
                                                                                    name="description"
                                                                                    onChange={this.onChange}
                                                                                    placeholder={this.props.t('counsellingProvider.description', { param: 'react' })}
                                                                                    value={Profile.description ? Profile.description : ""}
                                                                                />
                                                                                {validator.message('Description About The Session', Profile.description, 'required|max:200')}

                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <div className="row">
                                                                                <div className=" col-sm-12 col-lg-12">
                                                                                    <hr />
                                                                                    <button type="submit"
                                                                                        className="btn btn-primary mx-auto d-block btn-primary2 mt-1">{this.props.t(editable ? 'jobSearch.update' : 'JobPost.submit', { param: 'react' })}</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Validation(PostCounselling)));
